<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00008 13.1333C10.8351 13.1333 13.1334 10.8351 13.1334 8.00001C13.1334 5.16494 10.8351 2.86667 8.00008 2.86667C5.16502 2.86667 2.86675 5.16494 2.86675 8.00001C2.86675 10.8351 5.16502 13.1333 8.00008 13.1333ZM8.00008 14.3333C4.50228 14.3333 1.66675 11.4978 1.66675 8.00001C1.66675 4.5022 4.50228 1.66667 8.00008 1.66667C11.4979 1.66667 14.3334 4.5022 14.3334 8.00001C14.3334 11.4978 11.4979 14.3333 8.00008 14.3333ZM8.00008 6.49756C7.53984 6.49756 7.16675 6.12447 7.16675 5.66423C7.16675 5.20399 7.53984 4.8309 8.00008 4.8309C8.46032 4.8309 8.83342 5.20399 8.83342 5.66423C8.83342 6.12447 8.46032 6.49756 8.00008 6.49756ZM7.40008 8.28492C7.40008 7.94212 7.66871 7.66423 8.00008 7.66423C8.33145 7.66423 8.60008 7.94212 8.60008 8.28492V10.7102C8.60008 11.053 8.33145 11.3309 8.00008 11.3309C7.66871 11.3309 7.40008 11.053 7.40008 10.7102V8.28492Z"
      fill="#949494"
    />
  </svg>
</template>

<script>
export default {
  name: "IconAbout",
};
</script>

<style lang="scss" scoped></style>
