import { getPartnerInfo } from "@/modules/partner-profile/services/partner-profile.services";
import { IPartnerInfo } from "@/modules/partner-profile/types/partner-profile.types";

export default {
  namespaced: true,
  state: {
    partnerInfo: {} as IPartnerInfo,
  },
  mutations: {
    setPartnerInfo(state: any, payload: any) {
      state.partnerInfo = payload;
    },
  },
  actions: {
    async fetchUserInfo(
      context: any,
      payload: { role: string; partnerId: number }
    ) {
      try {
        const response = await getPartnerInfo(payload.role, payload.partnerId);
        context.commit("setPartnerInfo", response.data.partner);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
