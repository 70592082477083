<template>
  <div class="months">
    <div class="month" v-for="month in months" :key="month">
      <span class="month-text">{{ month }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonthsHeader",
  props: {
    months: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.months {
  display: flex;
}

.month {
  min-width: 135px;
  height: 22px;
  text-align: center;
}

.month-text {
  font-size: 11px;
  color: #aaaaaa;
  font-family: var(--proxima-nova-semibold);
}
</style>
