<template>
  <div class="event-empty-state">
    <span class="empty-state-text">Движений по открытию нет</span>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.event-empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-white);
  border-radius: 12px;
  padding: 2.5rem 0;
}

.empty-state-text {
  font-family: var(--proxima-nova-semibold);
  font-size: 15px;
  line-height: 20px;
  color: var(--main-black);
}
</style>
