import { IEquipment } from "@/modules/equipments/types/equipments.types";
import { getEquipments } from "@/modules/equipments/services/equipments.services";

export default {
  namespaced: true,
  state: {
    equipments: [] as IEquipment[],
    currentEquipment: {} as IEquipment,
  },
  mutations: {
    setEquipments(state: any, payload: any) {
      state.equipments = payload;
    },
    setCurrentEquipment(state: any, payload: IEquipment) {
      state.currentEquipment = payload;
    },
  },
  actions: {
    async fetchEquipments(
      context: any,
      payload: { partnerId: number; role: string; spotId: number | string }
    ) {
      try {
        const response = await getEquipments(
          payload.role,
          payload.partnerId,
          payload.spotId
        );
        context.commit("setEquipments", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
