<template>
  <div class="page">
    <div class="flex-wrapper">
      <TimeLineTitle
        :closest-opening-name="generalInfo.closestOpeningName"
        :openings-amount="generalInfo.openingsAmount"
        class="title"
      />
      <TimeLineActions class="actions" @update:switch="onSwitchHandler" />
      <div
        class="main-content main-timeline-container"
        v-if="currentSwitch.value === 'spots'"
      >
        <TimeLineStatisticLoader v-if="isDataLoading" />
        <TimeLineStatistic :statistics="statistics" :filters="filters" v-else />
      </div>
      <div
        class="main-content main-statistic-container"
        v-if="currentSwitch.value === 'statistics'"
      >
        <TimeLineStatisticDetails :details="details" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDetails,
  getGeneralInfo,
  getStatistic,
} from "@/modules/timeline/services/timeline.services";
import TimeLineTitle from "@/modules/timeline/components/TimeLineTitle.vue";
import TimeLineActions from "@/modules/timeline/components/TimeLineActions.vue";
import TimeLineStatistic from "@/modules/timeline/components/TimeLineStatistic.vue";
import TimeLineStatisticLoader from "@/modules/timeline/components/TimeLineStatisticLoader.vue";
import TimeLineStatisticDetails from "@/modules/timeline/components/Statistic/TimeLineStatisticDetails.vue";

export default {
  name: "TimelinePage",
  components: {
    TimeLineStatisticDetails,
    TimeLineStatisticLoader,
    TimeLineStatistic,
    TimeLineActions,
    TimeLineTitle,
  },
  data() {
    return {
      statistics: [],
      filters: [],
      response: null,
      isDataLoading: true,
      generalInfo: {
        openingsAmount: "",
        closestOpeningName: "",
      },
      currentSwitch: {
        label: "споты",
        value: "spots",
      },
      details: [],
    };
  },
  methods: {
    onSwitchHandler(currentSwitch) {
      this.currentSwitch = { ...currentSwitch };
    },
    async initGeneralInfo() {
      try {
        const response = await getGeneralInfo();
        this.generalInfo.openingsAmount = response.data.openingsAmount;
        this.generalInfo.closestOpeningName = response.data.closestOpeningName;
      } catch (e) {
        console.log(e);
      }
    },
    async initStatistic() {
      try {
        const response = await getStatistic();
        this.statistics = response.data.statistics;
        this.filters = response.data.filters;
      } catch (e) {
        this.$toast.error(e.response.reason);
      } finally {
        const timeoutId = await setTimeout(() => {
          this.isDataLoading = false;
          clearTimeout(timeoutId);
        }, 1000);
      }
    },
    async initDetails() {
      try {
        const response = await getDetails();
        this.details = response.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    await this.initStatistic();
    await this.initGeneralInfo();
    await this.initDetails();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.page {
  background: url("@/modules/timeline/images/background.png") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  width: 100vw;
  height: 100vh;
}

.main-content {
  width: 100%;
  height: 525px;
  border-radius: 12px;
}

.flex-wrapper {
  width: 1800px;
  margin: 0 auto;
}

.title {
  margin-bottom: 2rem;
}

.actions {
  margin-bottom: 1rem;
}

@media screen and (min-width: 1000px) {
  .flex-wrapper {
    max-width: 900px;
  }
}

@media screen and (max-width: 1300px) {
  .flex-wrapper {
    max-width: 1005px;
  }
}

@media screen and (min-width: 1529px) {
  .flex-wrapper {
    max-width: 1005px;
  }
}

@media screen and (max-width: 1530px) {
  .flex-wrapper {
    max-width: 1005px;
  }
}

@media screen and (max-width: 1530px) {
  .flex-wrapper {
    max-width: 1005px;
  }
}

@media screen and (max-width: 1600px) {
  .flex-wrapper {
    max-width: 1005px;
  }
}

@media screen and (min-width: 1601px) {
  .flex-wrapper {
    max-width: 1600px;
  }
}
</style>
