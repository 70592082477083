import { createStore } from "vuex";

import authStore from "@/modules/auth/store/auth.store";
import modalsStore from "@/modules/layouts/store/modals.store";
import partnerTasksStore from "@/modules/partner-tasks/store/partner-tasks.store";
import managerDashboardStore from "@/modules/manager-dashboard/store/manager-dashboard.store";
import instructorDashboardStore from "@/modules/instructor-dashboard/store/instructor-dashboard.store";
import partnerProfileStore from "@/modules/partner-profile/store/partner-profile.store";
import progressStore from "@/modules/progress/store/progress.store";
import decorationsStore from "@/modules/decorations/store/decorations.store";
import equipmentsStore from "@/modules/equipments/store/equipments.store";
import firebaseStore from "@/plugins/firebase/firebase.store";
import notificationsStore from "@/modules/notifications/store/notifications.store";
import buildingsStore from "@/modules/buildings/store/buildings.store";
import financesStore from "@/modules/finances/store/finances.store";
import executiveStore from "@/modules/executive/store/executive.store";
import documentsStore from "@/modules/documents/store/documents.store";

export function initStore() {
  return createStore({
    modules: {
      firebaseStore,
      authStore,
      partnerTasksStore,
      modalsStore,
      managerDashboardStore,
      instructorDashboardStore,
      partnerProfileStore,
      progressStore,
      decorationsStore,
      equipmentsStore,
      notificationsStore,
      buildingsStore,
      financesStore,
      executiveStore,
      documentsStore,
    },
  });
}
