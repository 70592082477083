import { INotification } from "@/modules/notifications/types/notifications.types";
import { getNotifications } from "@/modules/notifications/services/notifications.services";

export default {
  namespaced: true,
  state: {
    readNotifications: [] as Array<INotification>,
    newNotifications: [] as Array<INotification>,
  },
  mutations: {
    setReadNotifications(state: any, payload: Array<INotification>) {
      state.readNotifications = payload.sort(
        (a: INotification, b: INotification) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return new Date(b.createTime) - new Date(a.createTime);
        }
      );
    },
    setNewNotifications(state: any, payload: Array<INotification>) {
      state.newNotifications = payload.sort(
        (a: INotification, b: INotification) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return new Date(b.createTime) - new Date(a.createTime);
        }
      );
    },
  },
  actions: {
    async fetchNotifications(context: any) {
      try {
        const response = await getNotifications();
        context.commit("setReadNotifications", response.data.read);
        context.commit("setNewNotifications", response.data.notRead);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
