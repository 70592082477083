import $api from "@/api";
import { Roles } from "@/enums/enums";
import { Undefinable } from "@/types/types";

export async function getDecorations(
  role: string,
  partnerId: number,
  spotId: number | string
) {
  if (role !== Roles.partner && !partnerId) {
    throw new Error("Partner id is undefined on partner role");
  }

  switch (role) {
    case Roles.instructor:
      return $api.get(
        `/instructor/partners/${partnerId}/${spotId}/decorations`
      );

    case Roles.manager:
      return $api.get(`/manager/partners/${partnerId}/${spotId}/decorations`);

    case Roles.executive:
      return $api.get(`/executive/partners/${partnerId}/${spotId}/decorations`);

    case Roles.partner:
      return $api.get(`/partner/${spotId}/decorations`);
  }
}

export async function createDecoration(
  role: string,
  partnerId: Undefinable<number>,
  body: FormData,
  spotId: number | string
) {
  switch (role) {
    case Roles.instructor:
      return $api.post(
        `/instructor/partners/${partnerId}/${spotId}/decorations`,
        body
      );

    case Roles.executive:
      return $api.post(
        `/executive/partners/${partnerId}/${spotId}/decorations`,
        body
      );

    case Roles.partner:
      return $api.post(`/partner/${spotId}/decorations`, body);
  }
}

export async function deleteDecoration(role: string, decorId: number) {
  if (role !== Roles.partner && !decorId) {
    throw new Error("Partner id is undefined on partner role");
  }

  switch (role) {
    case Roles.instructor:
      return $api.delete(`/instructor/partners/decorations/${decorId}`);

    case Roles.executive:
      return $api.delete(`/executive/partners/decorations/${decorId}`);

    case Roles.partner:
      return $api.delete(`/partner/decorations/${decorId}`);
  }
}

export async function updateDecor(role: string, body: FormData) {
  switch (role) {
    case Roles.instructor:
      return $api.patch(`/instructor/partners/decorations`, body);

    case Roles.executive:
      return $api.patch(`/executive/partners/decorations`, body);

    case Roles.partner:
      return $api.patch(`/partner/decorations`, body);
  }
}
