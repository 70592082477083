<template>
  <div class="statistic-details">
    <div class="details-container">
      <div class="detail" v-for="(detail, index) in details" :key="index">
        <div class="title-container item">
          <h2 class="title">{{ detail.name }}</h2>
          <div class="amount">{{ roundedValue(detail.percentage) }}%</div>
        </div>
        <div
          class="item-container"
          :class="withDivider(line.divider)"
          v-for="(line, index) in detail.lines"
          :key="index"
        >
          <span class="item-title">{{ line.name }}</span>
          <span class="item-amount">{{ roundedValue(line.value) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeLineStatisticDetails",
  props: {
    details: {
      type: Array,
      required: true,
    },
  },
  computed: {
    withDivider() {
      return function (divider) {
        return divider ? "with-divider" : "";
      };
    },
    roundedValue() {
      return function (num) {
        if (num % 1 !== 0) {
          return Math.round(num * 100) / 100;
        } else {
          return num;
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.statistic-details {
  width: 100%;
  height: 100%;
  background-color: var(--main-white);
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  flex: 3;
  row-gap: 30px;
  column-gap: 1rem;
}

.detail {
  padding: 1.5rem 1rem;
  background-color: var(--main-light-gray);
  max-width: 321px;
  border-radius: 12px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-container {
  margin-bottom: 1rem;
}

.title,
.amount {
  font-family: var(--proxima-nova-semibold);
  font-weight: 600;
  color: var(--main-black);
  font-size: 22px;
}

.item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--proxima-nova-regular);
  font-size: 15px;
  padding-bottom: 0.25rem;
}

.with-divider {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 0.5rem;
}
</style>
