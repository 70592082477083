import { getDocuments } from "@/modules/documents/services/documents.services";
import type { Document } from "@/modules/documents/types/documents.types";

export default {
  namespaced: true,
  state: {
    documents: [] as Document[],
    currentDocument: {} as Document,
  },
  mutations: {
    setDocuments(state: any, payload: Document[]) {
      state.documents = payload;
    },
    setCurrentDocument(state: any, payload: Document) {
      state.currentDocument = payload;
    },
  },
  actions: {
    async fetchDocuments(context: any, payload: any) {
      try {
        const response = await getDocuments(
          payload.role,
          payload.partnerId,
          payload.spotId
        );
        context.commit("setDocuments", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
