<template>
  <div class="timeline">
    <GanttHeader />
    <section class="gantt-timeline-section">
      <div class="gantt-timeline">
        <div class="instructors">
          <InstructorContainer
            v-for="(instructor, index) in instructors"
            :key="instructor?.id"
            :instructor="instructor"
            :opening="openings[index]"
            :opening-height="instructor.height"
            class="instructor"
          />
        </div>
        <div class="gantt-statistic">
          <!-- Lines for months indicators -->
          <div class="horizontal-lines">
            <div
              class="today-line"
              :style="{ left: todayLineLeftMargin }"
            ></div>
            <div class="horizontal-lines-wrapper">
              <div
                v-for="(month, index) in months"
                :style="{ left: `${(index + 1) * widthStep}px` }"
                class="horizontal-line"
              ></div>
            </div>
          </div>
          <!-- Months title -->
          <MonthsHeader :months="months" />
          <GanttOpening
            v-for="(opening, index) in openings"
            :key="index"
            :opening="opening"
            :instructor="instructors[index]"
            :diagram-start="minStartString"
            @update:openingHeight="handleOpeningHeight"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InstructorContainer from "@/modules/timeline/components/Gantt/InstructorContainer.vue";
import GanttHeader from "@/modules/timeline/components/Gantt/GanttHeader.vue";
import MonthsHeader from "@/modules/timeline/components/Gantt/MonthsHeader.vue";
import GanttOpening from "@/modules/timeline/components/Gantt/GanttOpening.vue";

export default {
  name: "TimeLineStatistic",
  components: {
    GanttOpening,
    MonthsHeader,
    GanttHeader,
    InstructorContainer,
  },
  data() {
    return {
      months: [],
      widthStep: 135,
      startHeight: 26,
      instructorContainerHeights: [],
      instructors: [],
      openings: [],
      minStart: null,
      maxEnd: null,
      minStartString: "",
    };
  },
  props: {
    statistics: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  computed: {
    todayLineLeftMargin() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      const startMonth = this.getMonth(this.minStartString);
      const result = currentMonth - startMonth;
      const daysWidth = Math.trunc(
        (Number((currentDay / 30).toFixed(2)) + result) * 135
      );
      return `${daysWidth}px`;
    },
    getMonth() {
      return function (date) {
        if (!date) {
          return 0;
        }

        return +date.split("T")[0].split("-")[1] - 1;
      };
    },
  },
  methods: {
    initMonths() {
      const maxEndDate = new Date(this.maxEnd);
      const minStartDate = new Date(this.minStart);
      let currentDate = new Date(this.minStart);

      while (currentDate <= maxEndDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();

        this.months.push(
          new Date(year, month).toLocaleDateString("ru-RU", {
            month: "long",
          })
        );

        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    },
    initData() {
      this.instructors = this.statistics.map((opening, index) => {
        const instructorExists = this.instructors.some(
          (instructor) => instructor.id === opening.instructor.id
        );

        if (!instructorExists) {
          opening.instructor["height"] = "fit-content";
          return { ...opening.instructor, openings: opening.openings };
        }
      });

      this.openings = this.statistics.map((item, index) => {
        return {
          ...item.openings,
        };
      });

      const existOpening = this.statistics.find((statistic) => {
        if (statistic.openings.length) {
          return statistic;
        }
      });

      this.minStartString = existOpening.openings[0].startDate;
      this.minStart = new Date(existOpening.openings[0].startDate);
      this.maxEnd = new Date(existOpening.openings[0].endDate);

      this.statistics.forEach((item) => {
        item.openings.forEach((opening) => {
          const tempEndDate = new Date(opening?.endDate);
          const tempStartDate = new Date(opening?.startDate);

          this.maxEnd = tempEndDate > this.maxEnd ? tempEndDate : this.maxEnd;

          if (tempStartDate < this.minStart) {
            this.minStart = tempStartDate;
            this.minStartString = opening?.startDate;
          }
        });
      });
    },
    handleOpeningHeight(event) {
      const instructor = this.instructors.find(
        (item) => item.id === event.instructor.id
      );
      instructor.height = `${event.height}px`;
    },
  },
  mounted() {
    this.initData();
    this.initMonths();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.timeline {
  display: flex;
  flex-direction: column;
  background-color: var(--main-white);
  //width: 100%;
  padding: 1.5rem 0;
  border-radius: 12px;
  //height: 525px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.gantt-timeline-section {
  min-height: 100%;
}

.gantt-timeline {
  display: flex;
  padding: 0 0.5rem;
  min-height: 100%;
}

.gantt-statistic {
  width: 810px;
  position: relative;
  z-index: 999;
}

.horizontal-lines {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  min-height: 100%;
  display: flex;
}

.horizontal-lines-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.horizontal-line {
  height: 100%;
  position: absolute;
  top: 22px;
  bottom: 0;
  width: 1px;
  background-color: #eeeeee;
  z-index: -1;
}

.today-line {
  height: 100%;
  position: absolute;
  top: 22px;
  bottom: 0;
  width: 2px;
  background-color: #000;
  z-index: -1;
}

.instructors {
  width: 154px;
  padding-right: 0.5rem;
  padding-top: 22px;
}

.instructor {
  margin-bottom: 1rem;
}
</style>
