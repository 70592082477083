import AuthPage from "@/modules/auth/views/AuthPage.vue";
import TestPage from "@/modules/auth/views/TestPage.vue";

export default [
  {
    name: "Auth",
    component: AuthPage,
    path: "/auth",
  },
  {
    name: "Test",
    component: TestPage,
    path: "/test",
  },
];
