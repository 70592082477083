import $api, { API_URL } from "@/api";
import axios from "axios";

class AuthService {
  async login(body: any) {
    return $api.post("/auth/login", {
      username: body.email,
      password: body.password,
    });
  }

  async checkRole() {
    return $api.get("/auth/role");
  }

  async getInfo() {
    return $api.get("auth/get-info");
  }
}

export async function fetchRefreshToken(refresh: string) {
  return axios.post(
    `${API_URL}/auth/refresh-token`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer_${refresh}`,
      },
    }
  );
}

export const authService = new AuthService();
