import { getBuildings } from "@/modules/buildings/services/buildings.services";
import { IBuilding } from "@/modules/buildings/types/buildings.types";

export default {
  namespaced: true,
  state: {
    buildings: [],
    currentBuilding: { files: [] },
  },
  mutations: {
    setBuildings(state: any, payload: any) {
      state.buildings = payload;
    },
    setCurrentBuilding(state: any, payload: any) {
      state.currentBuilding = payload;
    },
    clearCurrentBuilding(state: any) {
      state.currentBuilding = { files: [] };
    },
  },
  actions: {
    async fetchBuildings(context: any, payload: any) {
      try {
        const response = await getBuildings(
          payload.role,
          payload.partnerId,
          payload.spotId
        );
        context.commit("setBuildings", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
