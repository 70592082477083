export default {
  namespaced: true,
  state: {
    modalType: "",
    prevModalType: "",
    isShownModal: false,
    modalData: {},
    sideModalData: {},
    body: {},
  },
  mutations: {
    closeModal(state: any) {
      state.isShownModal = false;
    },
    showModal(state: any, payload: any) {
      state.isShownModal = true;
      state.modalData = payload.data || state.modalData;
      state.modalType = payload.modalType;
    },
    setPrevModalType(state: any, payload: any) {
      state.prevModalType = payload.prev;
      state.modalType = payload.modalType;
      state.sideModalData = payload.modalData;
    },
    setSideModalData(state: any, payload: any) {
      state.sideModalData = payload;
    },
    setStructureValue(state: any, { structureIndex, value }: any) {
      state.modalData.structure[structureIndex].value = value;
    },
  },
};
