import $api from "@/api";
import { Roles } from "@/enums/enums";

export async function getActs() {
  return $api.get("/instructor/partners/");
}

export async function filterActs(body: Array<string>) {
  return $api.post("/instructor/partners", body);
}

export async function updateAct(role: Roles, body: unknown) {
  switch (role) {
    case Roles.instructor:
      return $api.patch("/instructor/partners", body);

    case Roles.executive:
      return $api.patch("/executive/partners", body);
  }
}

export async function instructorDashboardSearch(value: string) {
  return $api.get(`/instructor/partners?search_string=${value}`);
}
