import $api from "@/api";
import { Roles } from "@/enums/enums";

function toSnakeCase(str: string): string {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
}

function transformKeysToSnakeCase(obj: any): any {
  if (Array.isArray(obj)) {
    // Преобразуем каждый элемент массива
    return obj.map(transformKeysToSnakeCase);
  } else if (obj !== null && typeof obj === 'object') {
    // Преобразуем каждый ключ объекта в snake_case
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        toSnakeCase(key), // Преобразуем ключ в snake_case
        transformKeysToSnakeCase(value) // Рекурсивно обрабатываем значение
      ])
    );
  }
  // Если это примитив, возвращаем как есть
  return obj;
}

export async function getOutlays(
  role: string,
  partnerId?: number,
  spotId?: number | string
) {
  if (role !== Roles.partner && !partnerId) {
    throw new Error("Partner id is undefined on partner role");
  }

  switch (role) {
    case Roles.partner:
      return $api.get(`/finance/partner/${spotId}/get-outlays`);

    case Roles.manager:
      return $api.get(`/finance/stuff/get-outlays/${partnerId}/${spotId}`);

    case Roles.instructor:
      return $api.get(`/finance/stuff/get-outlays/${partnerId}/${spotId}`);

    case Roles.executive:
      return $api.get(`/finance/stuff/get-outlays/${partnerId}/${spotId}`);
  }
}

export async function getEstimate(
  role: string,
  partnerId: number,
  spotId: number | string
) {
  if (role !== Roles.partner && !partnerId) {
    throw new Error("Partner id is undefined on partner role");
  }

  switch (role) {
    case Roles.partner:
      return $api.get(`/finance/partner/${spotId}/get-estimate`);

    case Roles.manager:
      return $api.get(`/finance/stuff/get-estimate/${partnerId}/${spotId}`);

    case Roles.instructor:
      return $api.get(`/finance/stuff/get-estimate/${partnerId}/${spotId}`);

    case Roles.executive:
      return $api.get(`/finance/stuff/get-estimate/${partnerId}/${spotId}`);
  }
}

export async function getExpenses(
  role: string,
  partnerId: number,
  spotId: number | string
) {
  if (role !== Roles.partner && !partnerId) {
    throw new Error("Partner id is undefined on partner role");
  }

  switch (role) {
    case Roles.partner:
      return $api.get(`/finance/partner/${spotId}/expense-categories`);

    case Roles.manager:
      return $api.get(`/finance/stuff/expense-categories/${partnerId}/${spotId}`);

    case Roles.instructor:
      return $api.get(`/finance/stuff/expense-categories/${partnerId}/${spotId}`);

    case Roles.executive:
      return $api.get(`/finance/stuff/expense-categories/${partnerId}/${spotId}`);
  }
}

export async function getExpensesSums(
  role: string,
  partnerId: number,
  spotId: number | string
) {
  switch (role) {
    case "ROLE_PARTNER":
      return $api.get(`/finance/partner/${spotId}/get-expenses-sum`);

    case "ROLE_MANAGER":
      return $api.get(`/finance/stuff/get-expenses-sum/${partnerId}/${spotId}`);

    case "ROLE_INSTRUCTOR":
      return $api.get(`/finance/stuff/get-expenses-sum/${partnerId}/${spotId}`);

    case Roles.executive:
      return $api.get(`/finance/stuff/get-expenses-sum/${partnerId}/${spotId}`);
  }
}

export async function createExpense(
  body: any,
  spotId: number | string
) {
  const snakeCaseBody = transformKeysToSnakeCase(body);
  return $api.post(`/finance/partner/${spotId}/expenses`, snakeCaseBody);
}

export async function updateAverageBill(
  id: string | number,
  amount: string | number
) {
  return $api.post(`/finance/manager/update-average-bill/${id}/${amount}`);
}

export async function updateSalesPerDay(
  id: string | number,
  amount: string | number
) {
  return $api.post(`/finance/manager/update-sales-per-day/${id}/${amount}`);
}

export async function updateDaysInMonth(
  id: string | number,
  amount: string | number
) {
  return $api.post(`/finance/manager/update-days-in-month/${id}/${amount}`);
}

export async function updateIncomePerDay(
  id: string | number,
  amount: string | number
) {
  return $api.post(`finance/manager/update-income-per-day/${id}/${amount}`);
}

export async function updateBillsPerDay(
  id: string | number,
  amount: string | number
) {
  return $api.post(`finance/manager/update-bills-per-day/${id}/${amount}`);
}

export async function updateMinIncomePerMonth(
  id: string | number,
  amount: string | number
) {
  return $api.post(
    `finance/manager/update-min-income-per-month/${id}/${amount}`
  );
}

export async function updatePayback1(
  id: string | number,
  amount: string | number
) {
  return $api.post(`finance/manager/update-payback1/${id}/${amount}`);
}

export async function updatePayback2(
  id: string | number,
  amount: string | number
) {
  return $api.post(`finance/manager/update-payback2/${id}/${amount}`);
}

export async function updateUnitCost(
  id: string | number,
  cost: string | number
) {
  return $api.post(`finance/manager/update-unit-cost/${id}/${cost}`);
}

export async function updateAmount(
  id: string | number,
  amount: string | number
) {
  return $api.post(`finance/manager/update-amount/${id}/${amount}`);
}

export async function updateExpense(id: number, amount: number) {
  return $api.post(`/finance/manager/update-expected-amount/${id}/${amount}`);
}
