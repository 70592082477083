<template>
  <div class="upload-equipment-photo">
    <div class="preview-image-container" v-show="fileURL">
      <img :src="getFileURL" alt="preview" class="preview-image" />
    </div>
    <button class="delete-image-button" v-show="fileURL" @click="clearImage">
      <IconClose />
    </button>
    <input
      v-show="!fileURL"
      ref="fileInput"
      type="file"
      class="input-upload"
      accept="image/jpeg,image/png"
      @change="updateFile"
    />
    <div v-show="!fileURL" class="not-loaded">
      <div class="not-loaded-wrapper">
        <span class="not-loaded-wrapper-title">Выберите фото</span>
        <span class="not-loaded-wrapper-subtitle">до 12 МБ, jpeg</span>
      </div>
      <img
        src="../images/upload-background.svg"
        alt="upload"
        class="background-icon"
      />
    </div>
  </div>
</template>

<script>
import IconClose from "@/icons/IconClose.vue";

export default {
  name: "UIPhotoUpload",
  components: { IconClose },
  data() {
    return {
      file: null,
      fileURL: null,
    };
  },
  methods: {
    updateFile(event) {
      this.file = event.target.files[0];
      this.fileURL = URL.createObjectURL(this.file);
      this.$emit("update:file", this.file);
    },
    clearImage() {
      this.file = null;
      this.fileURL = null;
      this.$refs.fileInput.value = "";
      this.$emit("update:file", this.file);
    },
  },
  computed: {
    getFileURL() {
      if (this.fileURL) {
        return `${this.fileURL}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-equipment-photo {
  background-color: #f6f6f6;
  width: 100%;
  height: 160px;
  border-radius: 12px;
  position: relative;
}

.not-loaded {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  &-wrapper {
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 22px;
      line-height: 27px;
      font-family: var(--proxima-nova-semibold);
      color: var(--main-black);
    }

    &-subtitle {
      font-size: 13px;
      line-height: 18px;
      color: #aaaaaa;
      font-family: var(--proxima-nova-semibold);
    }
  }
}

.background-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.input-upload {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 8;
  cursor: pointer;
}

.preview-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.preview-image {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-image-button {
  background-color: #f6f6f6;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 13;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>
