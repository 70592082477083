import { getPartnerTasks } from "@/modules/partner-tasks/services/partner-tasks.services";
import {
  IPartnerTask,
  IStage,
} from "@/modules/partner-tasks/types/partner-tasks.types";

export default {
  namespaced: true,
  state: {
    stages: [] as Array<IStage>,
    activeTasks: [] as Array<IPartnerTask>,
  },
  mutations: {
    // TODO: Описать типы
    setStages(state: any, stages: any) {
      state.stages = stages;
    },
    setActiveTasks(state: any, stages: any) {
      state.activeTasks = stages
        .map((stage: any) =>
          stage.tasks.filter((task: any) => task.status === "ACTIVE")
        )
        .flat();
    },
  },
  actions: {
    async fetchStages(context: any) {
      try {
        const spotId = localStorage.getItem("spotId") || "";
        const response = await getPartnerTasks(spotId);
        context.commit("setStages", response.data);
        context.commit("setActiveTasks", response.data);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
