<template>
  <div class="surf-file-uploader">
    <div class="file-loader">
      <div class="file-subscription">
        <p class="not-files">Выберите или перенесите фото</p>
        <p class="format-subscription">до 20 мб, PDF, JPG, PNG</p>
      </div>
      <div class="actions">
        <input class="input-file" type="file" @change="handlerFiles" />
      </div>
    </div>
    <div class="files">
      <div v-for="(file, index) in files" :key="index" class="file">
        <img :src="file.preview" alt="Preview Image" class="image-preview" />
        <div class="info">
          <UIText :font-weight="600" font-size="15px">
            {{ file.name }}
          </UIText>
          <span class="info-subtitle">
            {{ formattedFileSize(file.size) }}
          </span>
        </div>
        <button class="delete" @click="deleteFile(index)">
          <IconClose />
          <!--          <img-->
          <!--              src="@/modules/UI-kit/images/upload-file/cancel-icon.svg"-->
          <!--              alt="Delete"-->
          <!--          />-->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UIText from "@/modules/UI-kit/UIText.vue";
import IconClose from "@/icons/IconClose.vue";

export default {
  name: "UIFileUploader",
  components: { IconClose, UIText },
  data() {
    return {
      files: [],
    };
  },
  props: {
    title: {
      type: String,
      default: "Файлы",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
  computed: {
    formattedFileSize() {
      return function (fileSize) {
        let size = fileSize;
        let unit = "B";

        if (size > 1024) {
          size /= 1024;
          unit = "KB";

          if (size > 1024) {
            size /= 1024;
            unit = "MB";
          }
        }

        return size.toFixed(2) + " " + unit;
      };
    },
  },
  methods: {
    handlerFiles(event) {
      const fileList = event.target.files;

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const reader = new FileReader();
        reader.onload = (event) => {
          this.files.push({
            preview: event.target.result,
            file: file,
            name: file.name,
            size: file.size,
          });
        };
        this.$emit("update:files", this.files);
        reader.readAsDataURL(file);
      }
    },
    deleteFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.surf-file-uploader {
  margin-top: 1rem;
}

.title {
  font-size: 22px;
  line-height: 27px;
  font-family: var(--proxima-nova-semibold);
}

.subtitle {
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  font-family: var(--proxima-nova-semibold);
  margin-top: 20px;
}

.file-loader {
  border-radius: 8px;
  margin-top: 1.5rem;
  cursor: pointer;
  padding: 22px;
  border: 1px dashed #aaaaaa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-file {
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.not-files {
  font-family: var(--proxima-nova-semibold);
  font-size: 15px;
  color: var(--main-black);
}

.files {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex: 2;
  gap: 1rem;
}

.file {
  display: flex;
  background-color: #f6f6f6;
  padding: 0.5rem;
  width: 270px;
  border-radius: 0.5rem;
}

.image-preview {
  margin-right: 0.5rem;
  width: 40px;
  height: 40px;
}

.info {
  display: flex;
  flex-direction: column;
}

.info-subtitle {
  color: #7e7e7e;
  font-size: 13px;
  font-family: var(--proxima-nova-regular);
}

.delete {
  margin-left: auto;
}

.format-subscription {
  color: #7e7e7e;
  font-size: 11px;
  line-height: 13px;
  font-family: var(--proxima-nova-semibold);
  margin-top: 4px;
}

.format-subscription {
  display: flex;
  flex-direction: column;
  text-align: center;
}
</style>
