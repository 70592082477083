import { Roles } from "@/enums/enums";
import PartnerDashboard from "@/modules/partner-dashboard/views/PartnerDashboard.vue";

export default [
  {
    name: "PartnerDashboard",
    component: PartnerDashboard,
    path: "/partner/main",
    meta: { role: [Roles.partner] },
  },
];
