import { Roles } from "@/enums/enums";
import ManagerDashboard from "@/modules/manager-dashboard/views/ManagerDashboard.vue";

export default [
  {
    name: "ManagerDashboard",
    component: ManagerDashboard,
    path: "/manager/main",
    meta: { role: [Roles.manager] },
  },
];
