import { Roles } from "@/enums/enums";

export const getMainPagePath = (role: string) => {
  switch (role) {
    case Roles.manager:
      return { path: "/manager/main" };

    case Roles.partner:
      return { path: "/partner/main" };

    case Roles.instructor:
      return { path: "/instructor/main" };

    case Roles.executive:
      return { path: "/executive/main" };

    default:
      return { path: "/auth" };
  }
};
