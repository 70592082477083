<template>
  <div class="radio-list">
    <span class="radio-list-title">
      {{ radioList.title }}
    </span>
    <ul class="list">
      <li
        class="item"
        v-for="(radio, radioIndex) in radioList.values"
        :key="radioIndex"
        :class="isActive(radio.checked)"
        @click="onRadioHandler(radioIndex, radio.value)"
      >
        {{ radio.value }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DashboardFiltersList",
  props: {
    radioList: {
      type: Object,
      required: true,
    },
    radioListIndex: {
      type: Number,
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },
  methods: {
    onRadioHandler(radioIndex, value) {
      this.$emit("radio", {
        radioIndex: radioIndex,
        radioListIndex: this.radioListIndex,
        variableName: this.variableName,
        value,
      });
    },
  },
  computed: {
    isActive: () => (checked) => {
      return checked ? "active" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-list {
}

.radio-list-title {
  font-size: 17px;
  font-family: var(--proxima-nova-semibold);
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.item {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f6f6f6;
  color: var(--main-black);
  border-radius: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 11px;
  cursor: pointer;
}

.active {
  background-color: var(--main-black);
  color: var(--main-white);
}
</style>
