<template>
  <div class="time-line-statistic-loader">
    <UILottieLoader :width="300" :height="300" />
  </div>
</template>

<script>
import UILottieLoader from "@/modules/UI-kit/UILottieLoader.vue";

export default {
  components: { UILottieLoader },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.time-line-statistic-loader {
  background-color: var(--main-white);
  width: 100%;
  height: 525px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
</style>
