<template>
  <div
    class="line-wrapper"
    :style="{
      marginLeft: getLineLeftMargin(line?.startDate, diagramStart),
      width: getLineWidth(line?.startDate, line?.endDate),
    }"
  >
    <div class="line">
      <span class="name">{{ line?.name }}</span>
      <div class="right-container">
        <span class="end-date">{{ getParsedDate(line?.endDate) }}</span>
        <button @click="showSubLines">
          <img
            src="@/modules/timeline/images/arrow-down.svg"
            alt="arrow-down"
            class="arrow"
            :class="rotateArrow"
          />
        </button>
      </div>
    </div>
    <div class="sub-lines" v-show="isShowSubLines">
      <div
        class="sub-line-container"
        v-for="subLine in line?.processes"
        :key="subLine?.stageName"
      >
        <div
          class="sub-line"
          v-if="subLine?.endDate && subLine?.startDate"
          :style="{
            marginLeft: subLineMarginLeft(subLine?.startDate, line?.startDate),
            width: getLineWidth(subLine?.startDate, subLine?.endDate),
          }"
        >
          {{ subLine?.stageName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainStatisticLine",
  props: {
    line: {
      type: Object,
      required: true,
    },
    diagramStart: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowSubLines: false,
    };
  },
  methods: {
    showSubLines() {
      this.isShowSubLines = !this.isShowSubLines;
    },
  },
  computed: {
    subLineMarginLeft() {
      return function (subLineStartDate, mainLineStartDate) {
        const mainLineStartMonth = this.getMonth(mainLineStartDate);
        const subLineStartMonth = this.getMonth(subLineStartDate);
        return `${(subLineStartMonth - mainLineStartMonth) * 135}px`;
      };
    },
    rotateArrow() {
      return this.isShowSubLines ? "" : "arrow-rotated";
    },
    getParsedDate: () => (date) => {
      const [month, day] = date.split("T")[0].split("-").splice(1, 2);
      return `${day}.${month}`;
    },
    getMonth() {
      return function (date) {
        if (!date) {
          return 0;
        }

        return +date.split("T")[0].split("-")[1] - 1;
      };
    },
    getDays() {
      return function (date) {
        if (!date) {
          return 0;
        }

        return +date.split("T")[0].split("-")[2];
      };
    },
    getLineWidth() {
      return function (start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);

        let months;
        months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
        months -= startDate.getMonth() + 1;
        months += endDate.getMonth() + 1;

        let days = this.getDays(end);

        if (days >= 31) {
          days = 30;
        }

        const daysWidth = Math.trunc(Number((days / 30).toFixed(2)) * 135);
        return `${months * 135 + daysWidth}px`;
      };
    },
    getLineLeftMargin() {
      return function (lineStartDate, diagramStartDate) {
        const lineStartMonth = this.getMonth(lineStartDate);
        const diagramStartMonth = this.getMonth(diagramStartDate);
        return `${(lineStartMonth - diagramStartMonth) * 135}px`;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.line-wrapper {
  display: flex;
  flex-direction: column;
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
  background-color: #eef4c8;
  border-radius: 12px;
  font-size: 11px;
  line-height: 13px;
  font-family: var(--proxima-nova-semibold);
  padding: 2px 0.5rem;
  color: #878e57;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.right-container {
  display: flex;
  align-items: center;
}

.end-date {
  margin-right: 0.5rem;
}

.arrow {
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}

.arrow-rotated {
  transform: rotate(360deg);
  transition: transform 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.sub-lines {
  display: flex;
  flex-direction: column;
}

.sub-line {
  background-color: #72b8a7;
  color: var(--main-white);
  height: 26px;
  width: 200px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 12px;
}
</style>
