<template>
  <div class="instructor" ref="instructor">
    <span class="instructor-name">
      {{ `${instructor.name} ${instructor.surname}` }}
    </span>
    <div class="instructor-info">
      <span class="instructor-info-text">открытия</span>
      <span class="instructor-info-text">
        {{ instructor.currentOpenings }}
      </span>
    </div>
    <div class="instructor-info" v-if="instructor.closest">
      <span class="instructor-info-text">ближайшее</span>
      <span class="instructor-info-text">
        {{ getDate(instructor.closest) }}
      </span>
    </div>
    <div class="instructor-info">
      <span class="instructor-info-text ready-to-take">готов взять</span>
      <span class="instructor-info-text ready-to-take">
        {{ instructor.openingCapacity }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstructorContainer",
  props: {
    instructor: {
      type: Object,
      required: true,
    },
    openingHeight: {
      type: String,
      required: true,
    },
    opening: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ro: null,
    };
  },
  computed: {
    getDate() {
      return function (date) {
        if (!date) {
          return "";
        } else {
          const [year, month, day] = date.split("T")[0].split("-");
          return `${day}.${month}`;
        }
      };
    },
    getOpeningHeight() {
      const heightValue = this.openingHeight.slice(0, -2);

      if (+heightValue < 86) {
        return "fit-content";
      } else {
        return this.openingHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.instructor {
  background-color: #eef4c8;
  padding: 0.5rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: v-bind(getOpeningHeight);
}

.instructor-name {
  font-size: 13px;
  font-family: var(--proxima-nova-semibold);
  line-height: 18px;
  color: black;
}

.instructor-info {
  display: flex;
  justify-content: space-between;
}

.instructor-info-text {
  color: #878e57;
  font-size: 11px;
  font-family: var(--proxima-nova-semibold);
  line-height: 13px;
}

.ready-to-take {
  color: #000;
}
</style>
