<template>
  <div class="upload-equipment-photo">
    <div class="preview-image-container" v-if="fileUrl">
      <img :src="getFileUrl" alt="preview" class="preview-image" />
    </div>
    <button
      class="delete-image-button"
      v-show="isEditable && fileUrl !== 'empty' && fileUrl"
      @click="clearImage"
    >
      <IconClose />
    </button>
    <input
      v-show="!fileUrl && isEditable"
      type="file"
      class="input-upload"
      accept="image/jpeg,image/png"
      @change="updateFile"
    />
    <div class="empty-state" v-show="!fileUrl && !isEditable">
      <img
        src="@/modules/equipments/images/empty-state-background-left.svg"
        alt="empty"
        class="background-left"
      />
      <img
        src="@/modules/equipments/images/empty-state-background-right.svg"
        alt="empty"
        class="background-right"
      />
      <span class="empty-state-text">Здесь пока нет фото</span>
    </div>
    <div v-show="!fileUrl && isEditable" class="not-loaded">
      <div class="not-loaded-wrapper">
        <span class="not-loaded-wrapper-title">Выберите фото</span>
        <span class="not-loaded-wrapper-subtitle">до 12 МБ, jpeg</span>
      </div>
      <img
        src="@/modules/equipments/images/upload-background.svg"
        alt="upload"
        class="background-icon"
      />
    </div>
  </div>
</template>

<script>
import IconClose from "@/icons/IconClose.vue";
import { computed } from "vue";
import { mapState } from "vuex";

export default {
  name: "UIUpdatePhoto",
  components: { IconClose },
  props: {
    fileUrl: {
      type: [String, null],
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      newUploadedFile: "",
      newFileUrl: "",
    };
  },
  methods: {
    clearImage() {
      this.$emit("update:clearImage");
    },
    updateFile(event) {
      this.file = event.target.files[0];
      this.newFileUrl = URL.createObjectURL(this.file);
      this.$emit("update:newFile", this.file, this.newFileUrl);
    },
  },
  computed: {
    ...mapState({
      decoration: (state) => state.decorationsStore.currentDecoration,
    }),
    getFileUrl() {
      if (this.fileUrl.includes("blob")) {
        return this.fileUrl;
      } else if (!this.fileUrl.includes("blob")) {
        return `${this.fileUrl}?v=${Date.now()}`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_base.scss";

.upload-equipment-photo {
  background-color: #f6f6f6;
  width: 100%;
  height: 160px;
  border-radius: 12px;
  position: relative;
}

.preview-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.preview-image {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-image-button {
  background-color: #f6f6f6;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 13;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.not-loaded {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  &-wrapper {
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 22px;
      line-height: 27px;
      font-family: var(--proxima-nova-semibold);
      color: var(--main-black);
    }

    &-subtitle {
      font-size: 13px;
      line-height: 18px;
      color: #aaaaaa;
      font-family: var(--proxima-nova-semibold);
    }
  }
}

.background-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.input-upload {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 8;
  cursor: pointer;
}

.empty-state {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-left {
  position: absolute;
  top: 0;
  left: 0;
}

.background-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.empty-state-text {
  line-height: 27px;
  font-size: 22px;
  font-family: var(--proxima-nova-semibold);
}
</style>
