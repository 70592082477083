<template>
  <div class="timeline-switcher">
    <div
      v-for="switcher in switchers"
      class="switch-item"
      :class="setActiveSwitch(switcher)"
      @click="changeCurrentSwitch(switcher)"
    >
      {{ switcher.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeLineSwitcher",
  data() {
    return {
      currentSwitch: {
        label: "споты",
        value: "spots",
      },
      switchers: [
        {
          label: "споты",
          value: "spots",
        },
        {
          label: "статистика",
          value: "statistics",
        },
      ],
    };
  },
  computed: {
    setActiveSwitch: function () {
      return (switcher) => {
        if (this.currentSwitch.value === switcher.value) {
          return "active";
        }
      };
    },
  },
  methods: {
    changeCurrentSwitch(currentSwitch) {
      this.currentSwitch = currentSwitch;
      this.$emit("update:switch", this.currentSwitch);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.timeline-switcher {
  display: flex;
  height: fit-content;
}

.switch-item {
  color: #aaaaaa;
  font-size: 22px;
  font-family: var(--proxima-nova-semibold);
  padding: 0 1rem;
  border-right: 1px solid #aaaaaa;
  cursor: pointer;
  height: fit-content;

  &:last-child {
    padding-right: 0;
    border-right: none;
  }

  &:first-child {
    padding-left: 0;
  }
}

.active {
  color: #000;
}
</style>
