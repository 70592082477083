import $api from "@/api";
import { Roles } from "@/enums/enums";

export async function getDocuments(
  role: string,
  partnerId: number,
  spotId: number | string
) {
  if (role !== Roles.partner && !partnerId) {
    throw new Error("Partner id is undefined on partner role");
  }

  switch (role) {
    case Roles.instructor:
      return $api.get(`/instructor/partners/${partnerId}/${spotId}/files`);

    case Roles.manager:
      return $api.get(`/manager/partners/${partnerId}/${spotId}/files`);

    case Roles.executive:
      return $api.get(`/executive/partners/${partnerId}/${spotId}/files`);

    case Roles.partner:
      return $api.get(`/partner/${spotId}/files`);
  }
}
