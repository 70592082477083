import $api from "@/api";

export async function getDashboardStages(body: any) {
  if (!body) {
    return $api.post("manager/partners");
  }

  return $api.post("manager/partners", body);
}

export async function searchPartner(searchString: string) {
  return $api.get(`manager/partners?search_string=${searchString}`);
}
