const getEnvVar = (key: string) => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }
  return process.env[key] || "";
};

export const NODE_ENV = getEnvVar("VUE_APP_NODE_ENV");
export const API_URL_PROD = getEnvVar("VUE_APP_API_PROD_URL");
export const API_URL_DEV = getEnvVar("VUE_APP_API_DEV_URL");
export const API_URL_LOCAL = getEnvVar("VUE_APP_API_LOCAL_URL");
