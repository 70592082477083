import $api from "@/api";
import { Roles } from "@/enums/enums";
import { Undefinable } from "@/types/types";

export async function getLastHistory(
  role: string,
  partnerId: Undefinable<number>,
  actId?: string | number
) {
  switch (role) {
    case Roles.partner:
      return $api.get(`/partner/${actId}/history`);

    case Roles.manager:
      return $api.get(`/manager/partners/${actId}/${partnerId}/history`);

    case Roles.instructor:
      return $api.get(`/instructor/partners/${actId}/${partnerId}/history`);

    case Roles.executive:
      return $api.get(`/executive/partners/${actId}/${partnerId}/history`);
  }
}

export async function getFullHistory(
  role: string,
  partnerId: Undefinable<number>,
  actId?: number | string
) {
  switch (role) {
    case Roles.partner:
      return $api.get(`/partner/${actId}/history/all`);

    case Roles.manager:
      return $api.get(`/manager/partners/${partnerId}/${actId}/history/all`);

    case Roles.instructor:
      return $api.get(`/instructor/partners/${partnerId}/${actId}/history/all`);

    case Roles.executive:
      return $api.get(`/executive/partners/${partnerId}/${actId}/history/all`);
  }
}

export async function getProgressStages(
  role: string,
  partnerId: Undefinable<number>,
  actId?: number | string
) {
  switch (role) {
    case Roles.partner:
      return $api.get(`/partner/${actId}/stages`);

    case Roles.manager:
      return $api.get(`/manager/partners/${partnerId}/${actId}/stages/`);

    case Roles.instructor:
      return $api.get(`/instructor/partners/${partnerId}/${actId}/stages/`);

    case Roles.executive:
      return $api.get(`/executive/partners/${partnerId}/${actId}/stages/`);
  }
}
