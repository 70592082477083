<template>
  <div class="loader-container">
    <img
      src="@/modules/UI-kit/images/loaders/manager-dashboard-loader.png"
      alt="loader"
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  img {
    width: 20px;
    height: 20px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
