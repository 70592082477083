export function snakeToCamel(str: any) {
  return str.replace(/_([a-z])/g, function (match: any, letter: any) {
    return letter.toUpperCase();
  });
}

export function convertKeysToCamelCase(obj: any): any {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item));
  }

  const converted = {};
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const camelCaseKey = snakeToCamel(key);
      // @ts-ignore
      converted[camelCaseKey] = convertKeysToCamelCase(obj[key]);
    }
  }

  return converted;
}
