import { Roles } from "@/enums/enums";
import NotificationsPage from "@/modules/notifications/views/NotificationsPage.vue";

export default [
  {
    name: "NotificationsPage",
    component: NotificationsPage,
    path: "/notifications",
    meta: { role: [Roles.partner, Roles.manager, Roles.instructor] },
  },
];
