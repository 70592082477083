import $api from "@/api";

export async function deviceRegistration(token: string) {
  return $api.post("firebase/user/register", {
    token: token,
  });
}

export async function removeDevice(token: string) {
  return $api.post("firebase/user/unregister", { token: token });
}
