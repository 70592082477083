<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M90.4089 58.1273L90.5699 56.6393L97.3226 52.736L91.0656 52.0568L91.2322 50.5161L100 51.4678L99.8291 53.048L93.2572 56.8777L99.3433 57.5383L99.1767 59.079L90.4089 58.1273Z"
      fill="black"
    />
    <path
      d="M88.8418 66.6695C88.3181 65.5846 88.2754 64.4221 88.7136 63.1818C89.1519 61.9415 89.9151 61.0647 91.0035 60.5513C92.1002 60.041 93.2717 60.0067 94.5182 60.4487C95.7646 60.8906 96.6497 61.654 97.1733 62.7389C97.7053 63.8268 97.7522 64.9908 97.314 66.2311C96.8758 67.4714 96.1083 68.3468 95.0117 68.8571C93.9233 69.3705 92.7559 69.4062 91.5095 68.9642C90.263 68.5223 89.3738 67.7574 88.8418 66.6695ZM90.0099 63.6414C89.7188 64.4655 89.766 65.2316 90.1516 65.9397C90.5456 66.6507 91.1747 67.1595 92.0388 67.4659C92.9113 67.7752 93.7198 67.7762 94.4643 67.4688C95.2087 67.1614 95.7265 66.5956 96.0177 65.7715C96.3118 64.9391 96.2661 64.1688 95.8804 63.4607C95.4918 62.7609 94.8613 62.2564 93.9888 61.947C93.1163 61.6376 92.3093 61.6325 91.5678 61.9316C90.8233 62.239 90.304 62.809 90.0099 63.6414Z"
      fill="black"
    />
    <path
      d="M83.5769 73.667L84.5186 72.4375L91.5155 77.8152L89.1573 80.8942C88.6154 81.6018 87.979 81.9975 87.2482 82.0813C86.512 82.1721 85.8328 81.9784 85.2103 81.5C84.5949 81.027 84.2322 80.4199 84.1221 79.6787C84.0137 78.9498 84.2305 78.2316 84.7724 77.524L86.189 75.6745L83.5769 73.667ZM85.9817 78.1863C85.7134 78.5366 85.5948 78.9018 85.6259 79.2819C85.6571 79.6619 85.8405 79.981 86.1761 80.239C86.5118 80.497 86.8669 80.5918 87.2414 80.5235C87.616 80.4552 87.9373 80.2459 88.2056 79.8956L89.4934 78.2142L87.2694 76.505L85.9817 78.1863Z"
      fill="black"
    />
    <path
      d="M73.9691 83.5498L75.1395 82.5365L77.6554 85.4526L81.1267 82.4473L78.6108 79.5311L79.7812 78.5178L85.5479 85.202L84.3774 86.2153L82.0172 83.4795L78.5459 86.4848L80.9062 89.2206L79.7357 90.2339L73.9691 83.5498Z"
      fill="black"
    />
    <path
      d="M66.9505 88.746C67.3083 87.5954 68.0703 86.7174 69.2363 86.112C70.4023 85.5065 71.5577 85.3889 72.7025 85.7592C73.8513 86.1374 74.73 86.9144 75.3384 88.0904C75.9469 89.2663 76.0723 90.4295 75.7145 91.5801C75.3607 92.7385 74.6008 93.6204 73.4348 94.2259C72.2687 94.8313 71.1113 94.945 69.9625 94.5668C68.8177 94.1965 67.9411 93.4234 67.3326 92.2475C66.7242 91.0715 66.5968 89.9044 66.9505 88.746ZM69.8691 87.3349C69.0944 87.7372 68.6062 88.3289 68.4047 89.1098C68.2072 89.8986 68.3194 90.7007 68.7413 91.516C69.1672 92.3391 69.7568 92.8933 70.51 93.1785C71.2632 93.4637 72.0272 93.4052 72.802 93.0029C73.5845 92.5965 74.0766 92.0029 74.2781 91.2219C74.4718 90.445 74.3557 89.645 73.9298 88.8218C73.5039 87.9987 72.9182 87.4425 72.1728 87.1532C71.4196 86.868 70.6517 86.9286 69.8691 87.3349Z"
      fill="black"
    />
    <path
      d="M60.0876 89.8275L61.5823 89.4279L63.5054 96.6455L66.0859 95.9555L66.4372 97.2736L59.7815 99.0532L59.4303 97.7351L62.0108 97.0451L60.0876 89.8275Z"
      fill="black"
    />
    <path
      d="M48.9427 92.2851C49.7519 91.3934 50.8126 90.9196 52.1248 90.8638C53.437 90.8079 54.5339 91.1899 55.4157 92.0097C56.2978 92.8383 56.7669 93.9142 56.823 95.2375C56.8791 96.5609 56.5026 97.6684 55.6934 98.5601C54.8846 99.4607 53.8241 99.9389 52.5119 99.9947C51.1997 100.051 50.1026 99.6642 49.2205 98.8356C48.3387 98.0158 47.8698 96.9442 47.8137 95.6209C47.7576 94.2976 48.1339 93.1856 48.9427 92.2851ZM52.1831 92.24C51.3112 92.2771 50.6194 92.607 50.1077 93.2298C49.5963 93.8614 49.36 94.6359 49.3989 95.5534C49.4382 96.4798 49.7392 97.2315 50.3018 97.8085C50.8644 98.3856 51.5817 98.6555 52.4536 98.6184C53.3342 98.581 54.0305 98.2509 54.5422 97.6281C55.0452 97.0057 55.2771 96.2313 55.2378 95.305C55.1985 94.3787 54.9019 93.6268 54.3481 93.0494C53.7854 92.4723 53.0638 92.2025 52.1831 92.24Z"
      fill="black"
    />
    <path
      d="M31.9432 90.1142L41.6882 93.2012L41.3294 94.3378L31.5844 91.2508L31.9432 90.1142Z"
      fill="black"
    />
    <path
      d="M22.6254 80.8326L23.8272 81.7218L23.789 89.5317L27.5283 84.4604L28.7726 85.3811L23.5329 92.4873L22.2566 91.543L22.2672 83.9267L18.63 88.8595L17.3857 87.9387L22.6254 80.8326Z"
      fill="black"
    />
    <path
      d="M15.9908 75.1074C17.1901 75.2018 18.2137 75.7514 19.0616 76.7562C19.9095 77.7609 20.2801 78.8635 20.1735 80.0638C20.0601 81.2698 19.4985 82.3004 18.4887 83.1555C17.479 84.0106 16.3745 84.3909 15.1752 84.2965C13.9692 84.2078 12.9423 83.6611 12.0943 82.6563C11.2464 81.6515 10.8792 80.5461 10.9926 79.3401C11.0993 78.1398 11.6575 77.112 12.6672 76.2569C13.677 75.4019 14.7848 75.0187 15.9908 75.1074ZM18.0115 77.6455C17.4481 76.9779 16.764 76.6322 15.9591 76.6085C15.1475 76.5905 14.3916 76.8779 13.6915 77.4708C12.9847 78.0693 12.576 78.7681 12.4654 79.5671C12.3547 80.3661 12.5811 81.0994 13.1445 81.767C13.7136 82.4413 14.4005 82.7904 15.2054 82.8141C16.0046 82.831 16.7576 82.5402 17.4644 81.9417C18.1713 81.3431 18.5828 80.6477 18.6991 79.8555C18.8098 79.0565 18.5805 78.3198 18.0115 77.6455Z"
      fill="black"
    />
    <path
      d="M12.6199 67.0892L13.2065 68.5231L5.04611 71.8732L3.57706 68.2822C3.23946 67.457 3.21842 66.7069 3.51393 66.0321C3.8061 65.3492 4.31515 64.8587 5.04106 64.5607C5.75882 64.266 6.46507 64.2576 7.15982 64.5354C7.84307 64.8084 8.35349 65.3575 8.69109 66.1827L9.57352 68.3398L12.6199 67.0892ZM7.51115 66.8962C7.34403 66.4876 7.08871 66.2012 6.74522 66.0368C6.40172 65.8725 6.03422 65.8707 5.64272 66.0314C5.25121 66.1921 4.99068 66.4518 4.8611 66.8103C4.73153 67.1689 4.75031 67.5524 4.91744 67.961L5.71965 69.9219L8.31336 68.8571L7.51115 66.8962Z"
      fill="black"
    />
    <path
      d="M8.94309 53.8495L9.22712 55.3729L5.44492 56.0806L6.28729 60.5985L10.0695 59.8908L10.3535 61.4142L1.68436 63.0362L1.40033 61.5129L4.94858 60.849L4.1062 56.331L0.557952 56.9949L0.273924 55.4716L8.94309 53.8495Z"
      fill="black"
    />
    <path
      d="M8.00057 45.0713C8.81209 45.9609 9.18491 47.0627 9.11906 48.3767C9.0532 49.6908 8.57208 50.7496 7.6757 51.5533C6.77051 52.3565 5.65765 52.7249 4.33712 52.6585C3.01658 52.5921 1.95055 52.1141 1.13903 51.2245C0.318714 50.3345 -0.0585165 49.2325 0.00734156 47.9185C0.0731996 46.6044 0.55872 45.5458 1.4639 44.7426C2.36028 43.9389 3.46874 43.5703 4.78928 43.6367C6.10982 43.7031 7.18025 44.1813 8.00057 45.0713ZM7.7457 48.3077C7.78945 47.4346 7.52549 46.714 6.95381 46.1459C6.37332 45.5774 5.6253 45.2701 4.70972 45.2241C3.78535 45.1776 3.01035 45.4083 2.38475 45.9161C1.75914 46.424 1.42446 47.1145 1.3807 47.9875C1.3365 48.8694 1.60024 49.5944 2.17192 50.1625C2.74405 50.7217 3.4923 51.0246 4.41668 51.0711C5.34105 51.1176 6.11582 50.8913 6.74099 50.3923C7.36659 49.8844 7.7015 49.1896 7.7457 48.3077Z"
      fill="black"
    />
    <path
      d="M10.4998 38.6457L10.0896 40.1399L2.89939 38.1586L2.19103 40.7383L0.877936 40.3765L2.70494 33.7229L4.01803 34.0847L3.30968 36.6644L10.4998 38.6457Z"
      fill="black"
    />
    <path
      d="M13.9907 27.749C14.3476 28.8998 14.2174 30.0558 13.6001 31.2171C12.9828 32.3784 12.0979 33.1319 10.9455 33.4775C9.78526 33.819 8.62164 33.6785 7.45459 33.0559C6.28754 32.4333 5.52555 31.5467 5.16859 30.3959C4.80386 29.2409 4.93016 28.0828 5.54748 26.9215C6.1648 25.7602 7.05357 25.0088 8.21378 24.6673C9.3662 24.3217 10.5259 24.4601 11.693 25.0827C12.86 25.7052 13.6259 26.594 13.9907 27.749ZM12.3864 30.5697C12.7965 29.798 12.8635 29.0334 12.5871 28.2758C12.303 27.514 11.7564 26.9172 10.9472 26.4856C10.1303 26.0498 9.33093 25.9285 8.54914 26.1217C7.76735 26.3149 7.17137 26.7973 6.76121 27.5689C6.3469 28.3483 6.27791 29.1169 6.55424 29.8745C6.83471 30.6244 7.38342 31.2172 8.20035 31.653C9.01728 32.0888 9.81457 32.214 10.5922 32.0286C11.374 31.8353 11.9721 31.349 12.3864 30.5697Z"
      fill="black"
    />
    <path
      d="M24.316 14.2132L16.7557 21.1029L15.955 20.2212L23.5152 13.3314L24.316 14.2132Z"
      fill="black"
    />
    <path
      d="M36.8943 10.7832L35.5248 11.3816L28.7909 7.44581L31.3074 13.2246L29.8894 13.8442L26.3632 5.74662L27.8175 5.11111L34.3979 8.92617L31.9501 3.30519L33.3681 2.68557L36.8943 10.7832Z"
      fill="black"
    />
    <path
      d="M45.3334 7.85683C44.6471 8.84661 43.6572 9.45485 42.3634 9.68155C41.0697 9.90826 39.9324 9.67279 38.9515 8.97514C37.9691 8.2688 37.364 7.26327 37.1361 5.95856C36.9083 4.65385 37.1375 3.50661 37.8237 2.51684C38.5084 1.51836 39.4977 0.905772 40.7914 0.679067C42.0852 0.452362 43.2232 0.692182 44.2056 1.39853C45.1865 2.09617 45.7909 3.09735 46.0187 4.40206C46.2465 5.70677 46.0181 6.85836 45.3334 7.85683ZM42.1265 8.32466C42.9861 8.17403 43.6291 7.75657 44.0554 7.07229C44.4803 6.37932 44.6137 5.58053 44.4558 4.67593C44.2963 3.76263 43.9001 3.05666 43.2672 2.558C42.6342 2.05935 41.888 1.88533 41.0284 2.03596C40.1601 2.18812 39.5127 2.60633 39.0864 3.29061C38.6687 3.97337 38.5396 4.7714 38.6991 5.68469C38.8585 6.59799 39.2504 7.30472 39.8746 7.8049C40.5076 8.30356 41.2582 8.47681 42.1265 8.32466Z"
      fill="black"
    />
    <path
      d="M53.9364 8.96395L52.4043 8.74973L53.6233 0L57.4602 0.53646C58.3419 0.659742 58.9994 1.01914 59.4327 1.61466C59.8746 2.2114 60.0414 2.89893 59.9329 3.67727C59.8257 4.44686 59.4774 5.06238 58.888 5.52383C58.3085 5.97776 57.578 6.14308 56.6962 6.0198L54.3915 5.69756L53.9364 8.96395ZM56.6749 4.63935C57.1115 4.70038 57.4871 4.62361 57.8018 4.40906C58.1165 4.1945 58.303 3.87734 58.3615 3.45756C58.42 3.03778 58.3272 2.68156 58.0832 2.38888C57.8392 2.09621 57.4989 1.91935 57.0624 1.85832L54.9672 1.56538L54.5797 4.3464L56.6749 4.63935Z"
      fill="black"
    />
    <path
      d="M67.3014 12.4559L65.845 11.9333L67.1424 8.3047L62.8231 6.7549L61.5257 10.3835L60.0693 9.86095L63.0431 1.54383L64.4995 2.06638L63.2823 5.47054L67.6016 7.02035L68.8188 3.61619L70.2752 4.13875L67.3014 12.4559Z"
      fill="black"
    />
    <path
      d="M75.1753 15.9437C73.9995 16.1983 72.8614 15.9664 71.7612 15.2479C70.6609 14.5295 69.9897 13.58 69.7477 12.3996C69.5106 11.2118 69.753 10.063 70.475 8.95344C71.197 7.84383 72.1459 7.16171 73.3217 6.90707C74.5023 6.64503 75.6428 6.87323 76.7431 7.59169C77.8433 8.31015 78.5121 9.2633 78.7493 10.4511C78.9912 11.6316 78.7513 12.7766 78.0292 13.8862C77.3072 14.9958 76.3559 15.6816 75.1753 15.9437ZM72.5121 14.094C73.2431 14.5713 73.9975 14.7056 74.7753 14.4967C75.5579 14.2804 76.1994 13.7876 76.7 13.0183C77.2055 12.2415 77.3966 11.4546 77.2735 10.6575C77.1503 9.8603 76.7232 9.22304 75.9922 8.74568C75.2537 8.26349 74.4956 8.12684 73.7179 8.33573C72.9475 8.54944 72.3096 9.04465 71.8042 9.82137C71.2988 10.5981 71.1039 11.3826 71.2197 12.175C71.3428 12.9721 71.7736 13.6118 72.5121 14.094Z"
      fill="black"
    />
    <path
      d="M79.5786 21.4273L78.4967 20.3196L83.8269 15.0952L81.959 13.1828L82.9325 12.2287L87.7501 17.1611L86.7767 18.1152L84.9088 16.2029L79.5786 21.4273Z"
      fill="black"
    />
    <path
      d="M87.2755 29.9805C86.1027 29.7121 85.17 29.0191 84.4772 27.9013C83.7844 26.7836 83.578 25.6387 83.858 24.4667C84.1455 23.2901 84.8509 22.3525 85.9742 21.6538C87.0975 20.9551 88.2455 20.7399 89.4183 21.0083C90.5985 21.272 91.535 21.9627 92.2279 23.0805C92.9207 24.1982 93.1233 25.3454 92.8358 26.5221C92.5558 27.694 91.8541 28.6294 90.7308 29.328C89.6075 30.0267 88.4557 30.2442 87.2755 29.9805ZM85.6454 27.1747C86.1057 27.9174 86.7323 28.3591 87.5251 28.5C88.3255 28.6362 89.1151 28.4621 89.8939 27.9777C90.6802 27.4887 91.1861 26.8569 91.4117 26.0826C91.6373 25.3083 91.5199 24.5498 91.0596 23.8071C90.5947 23.0569 89.9658 22.6114 89.1729 22.4705C88.3847 22.3372 87.5974 22.515 86.8111 23.0041C86.0248 23.4932 85.5166 24.1211 85.2863 24.8879C85.0607 25.6623 85.1804 26.4245 85.6454 27.1747Z"
      fill="black"
    />
    <path
      d="M93.7363 45.6468L91.5919 35.6362L92.7554 35.3861L94.8998 45.3967L93.7363 45.6468Z"
      fill="black"
    />
    <path
      d="M50.7334 42.2685C48.3702 50.7233 49.7207 62.898 51.7464 70H47.3584C46.008 60.1925 46.6823 49.3699 48.7078 42.6061L44.6576 45.3116L43.6439 44.9734V45.6498L42.9697 45.3116V45.988L40.6055 47.518L39.5937 47.0026L40.2689 48.0171L39.5937 48.3553L37.5681 47.6789L38.5809 49.7081L36.8929 50.7226H35.8801L36.4068 52.0754L31.4904 58.1628C29.4661 42.6061 38.2433 41.5915 46.0071 39.9006L43.6439 38.8867V38.5478H42.6311L41.9559 37.5333V38.2097L41.2807 37.8715V37.5333L40.6055 37.8709L37.2296 38.8867L36.5544 38.2097L36.4068 39.2242L35.5416 39.5624L34.8664 39.2242V40.2388L31.828 42.2685C36.5544 29.7549 43.6439 34.4896 48.7078 36.8564C48.7078 36.0447 45.782 33.3627 44.3191 32.1232C48.0327 28.7405 52.4214 30.0931 54.447 31.4468L51.7462 36.5196C58.8358 29.7565 64.2373 35.8424 67.6133 39.5619L65.2501 38.8867V37.8709L64.2373 38.2091L63.2245 37.8709L62.8869 36.8564L62.2117 37.5328H60.5237L60.1861 36.8564L59.8485 37.5328H57.823L57.1478 36.5182L56.8102 37.8709L52.759 38.8867C61.1989 37.8709 67.6133 46.3261 65.5877 55.7961L63.2245 51.7378L63.5621 50.7232H62.8869L62.2117 49.7087L62.5493 48.0177L61.1989 49.0323L60.1861 48.0177L60.5237 46.3268L59.5109 46.665L57.823 45.3122V44.2977L57.1478 44.6359L55.7974 43.9595L55.1222 42.9449L54.7846 43.9595L50.7334 42.2685Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "IconAvatarEmpty",
  props: {
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>

<style lang="scss" scoped></style>
