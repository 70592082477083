import { Roles } from "@/enums/enums";
import InstructorDashboard from "@/modules/instructor-dashboard/views/InstructorDashboardPage.vue";

export default [
  {
    name: "InstructorDashboard",
    component: InstructorDashboard,
    path: "/instructor/main",
    meta: { role: [Roles.instructor] },
  },
];
