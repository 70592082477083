import { Roles } from "@/enums/enums";
import $api from "@/api";

export async function getPartnerInfo(role: string, partnerId: number) {
  switch (role) {
    case Roles.instructor:
      return $api.get(`/instructor/partners/${partnerId}`);

    case Roles.manager:
      return $api.get(`/manager/partners/${partnerId}`);

    case Roles.executive:
      return $api.get(`/executive/partners/${partnerId}`);
  }

  throw new Error("Role is not correct");
}
