import { Roles } from "@/enums/enums";

export default [
  {
    name: "ExecutivePage",
    component: () => import("../views/ExecutiveDashboard.vue"),
    path: "/executive/main",
    meta: { role: [Roles.executive] },
  },
];
