<template>
  <li class="partner-task-item" @click.stop="showModalWindow">
    <div class="left">
      <div class="circle"></div>
      <UIText
        font-size="11px"
        :font-weight="600"
        font-family="proxima-nova-semibold"
      >
        {{ task.title }}
      </UIText>
    </div>
    <div class="right">
      <div class="status" :class="setSubStatus" v-if="!!task?.subStatus">
        {{ task.subStatus }}
      </div>
    </div>
  </li>
</template>

<script>
import { mapMutations } from "vuex";
import UIText from "@/modules/UI-kit/UIText.vue";
import { ModalTypes } from "@/enums/enums";

export default {
  name: "InstructorDashboardActsTask",
  components: { UIText },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    setSubStatus() {
      switch (this.task?.subStatus.toLowerCase()) {
        case "почти просрочено":
          return "canceled";

        case "просрочено":
          return "canceled";

        case "новое":
          return "done";
      }
    },
  },
  methods: {
    ...mapMutations({
      showModal: "modalsStore/showModal",
    }),
    showModalWindow() {
      this.showModal({
        data: this.task,
        modalType: ModalTypes.cppMainPopup,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.partner-task-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.left {
  display: flex;
  align-items: center;
}

.circle {
  width: 16px;
  height: 16px;
  background-color: #f6f6f6;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.status {
  font-size: 11px;
  padding: 5px 0.5rem;
  border-radius: 1.5rem;
}

.created {
  color: var(--main-white);
  background-color: var(--main-black);
}

.waiting {
  border: 1px solid var(--main-black);
  background-color: #d1e8ec;
  color: var(--main-black);
}

.done {
  background-color: var(--main-black);
  color: var(--main-white);
}

.canceled {
  background-color: #fca1a1;
  color: #980000;
}
</style>
