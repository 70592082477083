import $api from "@/api";

// POST methods
export async function executiveCreateBuilding(
  partnerId: any,
  body: FormData,
  spotId: number | string
) {
  return $api.post(`/executive/partners/${partnerId}/${spotId}/building`, body);
}

export async function executiveTaskComplete(taskId: string) {
  return $api.post(`/executive/task/${taskId}`);
}

export async function executiveCreateEquipment(body: any, partnerId: number) {
  return $api.post(`/executive/partners/${partnerId}/equipment`, body);
}

export async function executiveCreateEmployee(body: any, partnerId: number) {
  return $api.post(`/executive/partners/${partnerId}/employee`, body);
}

export async function executiveCreateDecor(body: any, partnerId: number) {
  return $api.post(`/executive/partners/${partnerId}/decorations`, body);
}

// PATCH methods
export async function executiveVariablesUpdate(taskId: string, body: any) {
  return $api.patch(`/executive/task/${taskId}`, body);
}

export async function executiveFilesUpdate(taskId: string, body: FormData) {
  return $api.patch(`/executive/task/${taskId}`, body);
}

export async function updatePartnerAct(body: any) {
  return $api.patch("/executive/partners", body);
}

export async function updatePartnerEquipment(body: any) {
  return $api.patch("/executive/partners/equipment", body);
}

export async function updatePartnerEmployee(body: any) {
  return $api.patch("/executive/partners/employee", body);
}

export async function updatePartnerDecoration(body: any) {
  return $api.patch("/executive/partners/decorations", body);
}

export async function updatePartnerBuilding(
  body: any,
  buildingId: number,
  spotId: number | string
) {
  return $api.patch(`/executive/building/${buildingId}/${spotId}`, body);
}

// GET methods
export async function getPartnerEquipments(partnerId: number) {
  return $api.get(`/executive/partners/${partnerId}/equipment`);
}

export async function getPartnerEmployee(partnerId: number) {
  return $api.get(`/executive/partners/${partnerId}/employee`);
}

export async function getPartnerDecor(partnerId: number) {
  return $api.get(`/executive/partners/${partnerId}/decorations`);
}

export async function getPartnerBuilding(buildingId: number) {
  return $api.get(`/executive/building/${buildingId}`);
}

export async function getPartnerInfo(partnerId: number) {
  return $api.get(`/executive/partners/${partnerId}`);
}

export async function getPartnerStages(
  partnerId: number,
  actId?: number | string
) {
  return $api.get(`/executive/partners/${partnerId}/${actId}/stages/`);
}

export async function getPartnerLastHistory(
  partnerId: number,
  actId?: number | string
) {
  return $api.get(`/executive/partners/${partnerId}/${actId}/history`);
}

export async function getPartnerAllHistory(
  partnerId: number,
  actId?: number | string
) {
  return $api.get(`/executive/partners/${partnerId}/${actId}/history/all`);
}

export async function getPartners() {
  return $api.get("/executive/partners/");
}

// DELETE methods
export async function deletePartnerEquipment(equipmentId: number) {
  return $api.delete(`/executive/partners/equipment/${equipmentId}`);
}

export async function deletePartnerEmployee(employeeId: number) {
  return $api.delete(`/executive/partners/employee/${employeeId}`);
}

export async function deletePartnerDecor(decorId: number) {
  return $api.delete(`/executive/partners/decorations/${decorId}`);
}
