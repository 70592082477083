<template>
  <section class="header">
    <UIText font-family="proxima-nova-semibold" font-size="28px">
      Текущие открытия
    </UIText>
    <div class="filters"></div>
  </section>
</template>

<script>
import UIText from "@/modules/UI-kit/UIText.vue";

export default {
  name: "GanttHeader",
  components: { UIText },
};
</script>

<style lang="scss" scoped></style>
