<template>
  <div class="timeline-actions">
    <UIDigitalClock />
    <TimeLineSwitcher @update:switch="onSwitchHandler" />
  </div>
</template>

<script>
import TimeLineSwitcher from "@/modules/timeline/components/TimeLineSwitcher.vue";
import UIDigitalClock from "@/modules/UI-kit/UIDigitalClock.vue";

export default {
  name: "TimeLineActions",
  components: { UIDigitalClock, TimeLineSwitcher },
  methods: {
    onSwitchHandler(currentSwitch) {
      this.$emit("update:switch", currentSwitch);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.timeline-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
</style>
