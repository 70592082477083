import { getDecorations } from "@/modules/decorations/services/decorations.services";
import { IDecoration } from "@/modules/decorations/types/decorations.types";

export default {
  namespaced: true,
  state: {
    decorations: [] as IDecoration[],
    currentDecoration: {} as IDecoration,
  },
  mutations: {
    setDecorations(state: any, payload: any) {
      state.decorations = payload;
    },
    setCurrentDecoration(state: any, payload: IDecoration) {
      state.currentDecoration = payload;
    },
  },
  actions: {
    async fetchDecorations(
      context: any,
      payload: { partnerId: number; role: string; spotId: number | string }
    ) {
      try {
        const response = await getDecorations(
          payload.role,
          payload.partnerId,
          payload.spotId
        );
        context.commit("setDecorations", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
