import $api from "@/api";

export async function managerRateBuilding(data: any) {
  return $api.post("/manager/task/rate-building", data);
}

export async function updateManagerVariables(body: any, taskId: string) {
  return $api.patch(`/manager/task/${taskId}`, body);
}

export async function updateManagerFiles(body: FormData, taskId: string) {
  return $api.patch(`/manager/task/${taskId}`, body);
}

export async function managerTaskComplete(data: any) {
  return $api.post(`/manager/task/${data}`);
}
