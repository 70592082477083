import $api from "@/api";

export async function getStatistic() {
  return $api.get("public/openings/statistics");
}

export async function getGeneralInfo() {
  return $api.get("public/openings/statistics/general");
}

export async function getDetails() {
  return $api.get("public/openings/details");
}
