export enum Roles {
  partner = "ROLE_PARTNER",
  manager = "ROLE_MANAGER",
  instructor = "ROLE_INSTRUCTOR",
  executive = "ROLE_EXECUTIVE",
}

export enum ModalTypes {
  cppMainPopup = "cppMainPopup",
  cppSidePopup = "cppSidePopup",
  addExpensesModal = "addExpensesModal",
  historyExpensesModal = "historyExpensesModal",
  createBuildingModal = "createBuildingModal",
  addDecoration = "addDecoration",
  addEquipment = "addEquipment",
  updateDecor = "updateDecor",
  updateEquipment = "updateEquipment",
  dashboardManagerFilters = "dashboardManagerFilters",
  partnerAct = "partnerAct",
  changeSpotModal = "changeSpotModal",
}
