<template>
  <div class="openings" ref="opening">
    <MainStatisticLine
      v-for="(line, index) in opening"
      :key="index"
      :line="line"
      :diagram-start="diagramStart"
      class="line-item"
    />
  </div>
</template>

<script>
import MainStatisticLine from "@/modules/timeline/components/Gantt/MainStatisticLine.vue";

export default {
  name: "GanttOpening",
  components: { MainStatisticLine },
  props: {
    opening: {
      type: Object,
      required: true,
    },
    instructor: {
      type: Object,
      required: true,
    },
    diagramStart: {
      type: String,
      required: true,
    },
  },
  data() {
    return { componentHeight: 0, ro: null };
  },
  mounted() {
    this.ro = new ResizeObserver(this.handleResize);
    this.ro.observe(this.$refs.opening);
  },
  beforeUnmount() {
    this.ro.unobserve(this.$refs.opening);
  },
  methods: {
    handleResize() {
      this.componentHeight = this.$el.clientHeight;
      this.$emit("update:openingHeight", {
        height: this.componentHeight,
        instructor: this.instructor,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.openings {
  margin-bottom: 1rem;
  min-height: 86px;
}

.line-item {
  margin-bottom: 4px;
}
</style>
