<template>
  <div class="timeline-title">
    <div class="logo-container">
      <img
        src="@/modules/timeline/images/logo.png"
        alt="logo"
        width="48"
        height="32"
      />
      <span class="logo-title">ОТКРЫТИЯ SURF COFFEE</span>
    </div>
    <div class="title-info-container">
      <span class="title-span openings-counter">
        всего открытий: {{ openingsAmount }}
      </span>
      <span class="title-span closest-opening">
        ближайшее открытие: {{ closestOpeningName }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeLineTitle",
  props: {
    openingsAmount: {
      type: [String, Number],
      required: true,
    },
    closestOpeningName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";

.timeline-title {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}

.logo-title {
  font-family: var(--proxima-nova-regular);
  font-size: 14px;
  color: var(--main-black);
}

.logo-container {
  display: flex;
  align-items: center;
}

.title-span {
  background-color: var(--main-white);
  color: var(--main-black);
  font-family: var(--proxima-nova-semibold);
  font-size: 13px;
  padding: 2px 0.5rem;
  border-radius: 4px;
}

.openings-counter {
  margin-right: 0.5rem;
}
</style>
