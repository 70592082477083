import $api from "@/api";

export async function createBuilding(data: any) {
  return $api.post("/partner/task/create-building", data);
}

export async function deleteBuilding(data: any) {
  return $api.post("/partner/task/delete-building", data);
}

export async function updatePartnerVariables(body: any, taskId: string) {
  return $api.patch(`/partner/task/${taskId}`, body);
}

export async function updatePartnerFiles(body: FormData, taskId: string) {
  return $api.patch(`/partner/task/${taskId}`, body);
}

export async function partnerTaskComplete(data: any) {
  return $api.post(`/partner/task/${data}`);
}
