import { Roles } from "@/enums/enums";
import PartnerTasksPage from "@/modules/partner-tasks/views/PartnerTasksPage.vue";

export default [
  {
    name: "PartnerTasksPage",
    component: PartnerTasksPage,
    path: "/partner/tasks",
    meta: { role: [Roles.partner] },
  },
];
