import CoffeeShopPage from "@/modules/coffee-shop/views/CoffeeShopPage.vue";
import { Roles } from "@/enums/enums";

export default [
  {
    name: "CoffeeShopPage",
    path: "/partner/shop/:value?",
    component: CoffeeShopPage,
    meta: { roles: [Roles.partner] },
  },
];
