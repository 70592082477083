import { IAct } from "@/modules/instructor-dashboard/types/instructor-dashboard.types";
import { getPartners } from "@/modules/executive/services/executive.services";

export default {
  namespaced: true,
  state: {
    acts: [] as IAct[],
  },
  mutations: {
    setActs(state: any, payload: IAct[]) {
      state.acts = payload;
    },
  },
  actions: {
    async fetchActs(context: any) {
      try {
        const response = await getPartners();
        context.commit("setActs", response.data.acts);
      } catch (e) {
        return e;
      }
    },
  },
};
