<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6792 2.32078C13.2515 1.89307 12.5581 1.89307 12.1303 2.32078L8.00094 6.45018L3.87155 2.32079C3.44384 1.89307 2.75038 1.89307 2.32267 2.32079C1.89496 2.7485 1.89496 3.44195 2.32267 3.86966L6.45207 7.99906L2.32078 12.1303C1.89307 12.558 1.89307 13.2515 2.32078 13.6792C2.74849 14.1069 3.44195 14.1069 3.86966 13.6792L8.00094 9.54794L12.1322 13.6792C12.5599 14.1069 13.2534 14.1069 13.6811 13.6792C14.1088 13.2515 14.1088 12.558 13.6811 12.1303L9.54982 7.99906L13.6792 3.86966C14.1069 3.44195 14.1069 2.74849 13.6792 2.32078Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconClose",
  props: {
    size: {
      type: Number,
      default: 8,
    },
    color: {
      type: String,
      default: "black",
    },
  },
};
</script>

<style lang="scss" scoped></style>
