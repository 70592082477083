import $api from "@/api";
import { Undefinable } from "@/types/types";
import { Roles } from "@/enums/enums";

export async function getBuildings(
  role: string,
  partnerId: Undefinable<number>,
  spotId: number | string
) {
  if (role !== Roles.partner && !partnerId) {
    throw new Error("Partner id is undefined on partner role");
  }

  switch (role) {
    case Roles.partner:
      return $api.get(`/partner/${spotId}/buildings`);

    case Roles.manager:
      return $api.get(`/manager/partners/${partnerId}/${spotId}/buildings`);

    case Roles.instructor:
      return $api.get(`/instructor/partners/${partnerId}/${spotId}/buildings`);

    case Roles.executive:
      return $api.get(`/executive/partners/${partnerId}/${spotId}/buildings`);
  }
}

export async function partnerCreateBuilding(
  formData: any,
  spotId: number | string
) {
  return $api.post(`/partner/${spotId}/building`, formData);
}

export async function partnerUpdateBuilding(formData: any, id: number) {
  return $api.patch(`/partner/building/${id}`, formData);
}

export async function instructorUpdateBuilding(formData: FormData, id: number) {
  return $api.patch(`/instructor/building/${id}`, formData);
}
