import { Nullable } from "@/types/types";
import { onMounted, onUnmounted } from "vue";

export const useOutsideClick = (
  ref: Nullable<any> = null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback = () => {}
) => {
  function handleOutsideClick(event: Event) {
    if (ref?.value && !ref?.value.contains(event.target)) {
      callback();
    }
  }

  onMounted(() => {
    document.addEventListener("mousedown", handleOutsideClick);
  });

  onUnmounted(() => {
    document.removeEventListener("mousedown", handleOutsideClick);
  });
};
