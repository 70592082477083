<template>
  <ul class="counters">
    <li
      class="partner-counter"
      :class="isActive(filter.selected)"
      v-for="(filter, index) in filters"
      :key="index"
      @click="toggleActive(filter.name)"
    >
      <UIText
        font-size="11px"
        font-family="proxima-nova-semibold"
        :font-weight="600"
      >
        {{ `${filter.name}: ${filter.amount}` }}
      </UIText>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import UIText from "@/modules/UI-kit/UIText.vue";

export default {
  name: "InstructorDashboardFilters",
  components: { UIText },
  computed: {
    ...mapState({
      filters: (state) => state.instructorDashboardStore.filters,
    }),
    isActive: () => (selected) => {
      return selected ? "selected" : "";
    },
  },
  methods: {
    ...mapMutations({
      toggleActiveFilters: "instructorDashboardStore/toggleActiveFilter",
    }),
    ...mapActions({
      filterActs: "instructorDashboardStore/filterActs",
    }),
    async toggleActive(name) {
      await this.filterActs({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.counters {
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  row-gap: 1rem;
}

.partner-counter {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--main-white);
  border-radius: 1.5rem;
  cursor: pointer;
  border: 1px solid transparent;
  display: flex;

  &:last-child {
    margin-right: 0;
  }
}

.selected {
  border: 1px solid var(--main-black);
}
</style>
