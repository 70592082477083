<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5578 10.1349C6.19838 9.82685 5.65727 9.86847 5.34919 10.2279C5.04111 10.5873 5.08274 11.1284 5.44216 11.4365L11.4422 16.5794C11.7632 16.8545 12.2368 16.8545 12.5578 16.5794L18.5578 11.4365C18.9172 11.1284 18.9588 10.5873 18.6508 10.2279C18.3427 9.86847 17.8016 9.82685 17.4422 10.1349L12 14.7997L6.5578 10.1349Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrow",
  props: {
    color: {
      type: String,
      default: "#AAAAAA",
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>

<style lang="scss" scoped></style>
