import {
  IProgressEvent,
  IProgressStage,
} from "@/modules/progress/types/progress.types";
import {
  getFullHistory,
  getLastHistory,
  getProgressStages,
} from "@/modules/progress/services/progress.services";

export default {
  namespaced: true,
  state: {
    fullHistory: [] as IProgressEvent[],
    lastHistory: [] as IProgressEvent[],
    stages: [] as IProgressStage[],
  },
  mutations: {
    setFullHistory(state: any, payload: any) {
      state.fullHistory = payload;
    },
    setLastHistory(state: any, payload: any) {
      state.lastHistory = payload;
    },
    setProgressStages(state: any, payload: any) {
      state.stages = payload;
    },
  },
  actions: {
    async fetchFullHistory(context: any, payload: any) {
      try {
        const response = await getFullHistory(
          payload.role,
          payload.partnerId,
          payload.spotId
        );
        context.commit("setFullHistory", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
    async fetchLastHistory(context: any, payload: any) {
      try {
        const response = await getLastHistory(
          payload.role,
          payload.partnerId,
          payload.spotId
        );
        context.commit("setLastHistory", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
    async fetchStages(context: any, payload: any) {
      try {
        const response = await getProgressStages(
          payload.role,
          payload.partnerId,
          payload.spotId
        );
        context.commit("setProgressStages", response?.data);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
