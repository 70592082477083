import { firebaseConfig } from "@/plugins/firebase/firebase";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import {
  deviceRegistration,
  removeDevice,
} from "@/plugins/firebase/firebase.services";

export default {
  namespaced: true,
  state: {
    token: null,
    app: null,
    messaging: null,
  },
  mutations: {
    initApp(state: any) {
      state.app = initializeApp(firebaseConfig);
    },
    initMessaging(state: any) {
      state.messaging = getMessaging();
    },
    initFirebaseToken(state: any, token: any) {
      state.token = token;
    },
    clearFirebaseState(state: any) {
      state.app = null;
      state.messaging = null;
      state.token = null;
    },
  },
  getters: {
    getMessagingState: (state: any) => {
      return state.messaging;
    },
  },
  actions: {
    async initFirebase(context: any) {
      try {
        context.commit("initApp");
        context.commit("initMessaging");
        const token = await getToken(context.state.messaging, {
          vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
        });
        context.commit("initFirebaseToken", token);
        const response = await deviceRegistration(token);
      } catch (e) {
        console.log(e);
        return e;
      }
    },
    async firebaseLogout(context: any) {
      try {
        if (context.state.token) {
          const response = await removeDevice(context.state.token);
          context.commit("clearFirebaseState");
        }
      } catch (e) {
        console.log(e);
        return e;
      }
    },
  },
};
