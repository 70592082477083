import PartnerProfilePage from "@/modules/partner-profile/views/PartnerProfilePage.vue";
import { Roles } from "@/enums/enums";

export default [
  {
    name: "PartnerProfilePage",
    path: "/profile/:id/:value",
    component: PartnerProfilePage,
    props: true,
    meta: { role: [Roles.manager, Roles.instructor, Roles.executive] },
  },
  {
    path: "/profile/:id",
    redirect: (to: any) => {
      return { path: `/profile/${to.params.id}/finances` };
    },
  },
];
