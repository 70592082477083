import $api from "@/api";
import { Roles } from "@/enums/enums";
import { Undefinable } from "@/types/types";

export async function getEquipments(
  role: string,
  partnerId: number,
  spotId: number | string
) {
  if (role !== Roles.partner && !partnerId) {
    throw new Error("Partner id is undefined on partner role");
  }

  switch (role) {
    case Roles.instructor:
      return $api.get(`/instructor/partners/${partnerId}/${spotId}/equipment`);

    case Roles.manager:
      return $api.get(`/manager/partners/${partnerId}/${spotId}/equipment`);

    case Roles.executive:
      return $api.get(`/executive/partners/${partnerId}/${spotId}/equipment`);

    case Roles.partner:
      return $api.get(`/partner/${spotId}/equipment`);
  }
}

export async function createEquipment(
  role: string,
  partnerId: Undefinable<number>,
  body: FormData,
  spotId: number | string
) {
  switch (role) {
    case Roles.instructor:
      return $api.post(
        `/instructor/partners/${partnerId}/${spotId}/equipment`,
        body
      );

    case Roles.executive:
      return $api.post(
        `/executive/partners/${partnerId}/${spotId}/equipment`,
        body
      );

    case Roles.partner:
      return $api.post(`/partner/${spotId}/equipment`, body);
  }
}

export async function deleteEquipment(role: string, decorId: number) {
  if (role !== Roles.partner && !decorId) {
    throw new Error("Partner id is undefined on partner role");
  }

  switch (role) {
    case Roles.instructor:
      return $api.delete(`/instructor/partners/equipment/${decorId}`);

    case Roles.executive:
      return $api.delete(`/executive/partners/equipment/${decorId}`);

    case Roles.partner:
      return $api.delete(`/partner/equipment/${decorId}`);
  }
}

export async function updateEquipment(role: string, body: FormData) {
  switch (role) {
    case Roles.instructor:
      return $api.patch(`/instructor/partners/equipment`, body);

    case Roles.executive:
      return $api.patch(`/executive/partners/equipment`, body);

    case Roles.partner:
      return $api.patch(`/partner/equipment`, body);
  }
}
