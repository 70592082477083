<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8957 5.19104C11.1483 4.93632 11.5616 4.93632 11.8141 5.19104C12.062 5.44096 12.062 5.8427 11.8141 6.09262L7.13756 10.809C6.88498 11.0637 6.47173 11.0637 6.21915 10.809L4.18586 8.75837C3.93805 8.50846 3.93805 8.10671 4.18586 7.8568C4.43844 7.60207 4.85169 7.60207 5.10427 7.8568L6.67836 9.44427L10.8957 5.19104Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCheck",
};
</script>

<style lang="scss" scoped></style>
