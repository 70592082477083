import {
  getDashboardStages,
  searchPartner,
} from "@/modules/manager-dashboard/services/manager-dashboard.services";

export default {
  namespaced: true,
  state: {
    columns: [],
    filters: [],
    defaultFilters: [],
    stages: [],
    searchLoader: false,
    filtersRequestBody: {
      cities: [],
      statuses: [],
      sub_statuses: [],
      date: {
        start: "",
        end: "",
      },
    },
  },
  mutations: {
    setDashboardColumns(state: any, payload: any) {
      state.columns = payload;
    },
    setFilters(state: any, payload: any) {
      state.filters = payload;
      state.defaultFilters = payload;
    },
    setStages(state: any, payload: any) {
      state.stages = payload;
    },
    toggleSearchLoader(state: any, payload: boolean) {
      state.searchLoader = payload;
    },
    setActiveRadio(
      state: any,
      { value, variableName, radioIndex, radioListIndex }: any
    ) {
      const radioList = state.filters.radio[radioListIndex].values;
      const radioItem = radioList[radioIndex];
      radioItem.checked = !radioItem.checked;

      const filtersRequestBody = state.filtersRequestBody[variableName];
      const index = filtersRequestBody.indexOf(value);

      if (index !== -1) {
        filtersRequestBody.splice(index, 1);
      } else {
        filtersRequestBody.push(value);
      }
    },
    setDate(state: any, payload: any) {
      state.filtersRequestBody.date = payload;
    },
    clearFiltersRequestBody(state: any) {
      state.filters = {
        ...state.defaultFilters,
      };
      state.filtersRequestBody = {
        cities: [],
        statuses: [],
        sub_statuses: [],
        date: {
          start: "",
          end: "",
        },
      };
    },
  },
  actions: {
    async fetchDashboardStages(context: any, payload: any) {
      try {
        const response = await getDashboardStages(payload);
        context.commit("setDashboardColumns", response.data.columns);
        context.commit("setFilters", response.data.filters);
      } catch (e: any) {
        return e.response;
      }
    },
    async searchStages(
      context: any,
      { searchString }: { searchString: string }
    ) {
      try {
        const response = await searchPartner(searchString);
        context.commit("setStages", response.data?.stages);
        context.commit("toggleSearchLoader", false);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
