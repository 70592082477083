<template>
  <div class="partner-info-modal">
    <div class="main-modal">
      <div class="top">
        <UIText
          font-family="proxima-nova-semibold"
          :font-weight="600"
          font-size="34px"
        >
          Партнер
        </UIText>
      </div>
      <div class="actions" v-show="!isInput">
        <div class="item">
          <div class="item-title">Спот</div>
          <div class="item-value">{{ currentAct.name }}</div>
        </div>
        <div class="item">
          <div class="item-title">Статус</div>
          <div class="item-value">
            <div class="status">
              {{ currentAct.processes.slice(-1)[0] || "" }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-title">Партнер</div>
          <div class="item-value">
            {{ `${currentAct.partner.name} ${currentAct.partner.surname}` }}
          </div>
        </div>
        <div class="item">
          <div class="item-title">Планируемая дата открытия</div>
          <div class="item-value">{{ getDateFormat(currentAct.endDate) }}</div>
        </div>
        <div class="item">
          <div class="item-title">Последнее действие</div>
          <div class="item-value">
            {{ currentAct.event?.title || "" }}
          </div>
        </div>
        <div class="item" v-show="currentAct.tasks.length">
          <div class="item-title">Требуемое действие</div>
          <div class="item-value">{{ currentAct.tasks[0]?.title }}</div>
        </div>
      </div>
      <div
        class="actions"
        :style="{ marginBottom: '2rem', marginTop: 'auto' }"
        v-show="isInput"
      >
        <UIInput v-model="state.spot" place-holder="Спот" />
        <UIInput
          v-model="state.date"
          place-holder="Планируемая дата открытия"
        />
      </div>
    </div>
    <div class="buttons" :style="{ paddingTop: '1rem' }" v-show="!isInput">
      <UIButton class="actions-button" @click="toPartner">
        Перейти в профиль
      </UIButton>
      <UIButton
        class="actions-button"
        button-style="ghost"
        @click="isInput = !isInput"
      >
        <UIText
          :font-weight="600"
          font-family="proxima-nova-semibold"
          font-size="17px"
        >
          Редактировать
        </UIText>
      </UIButton>
    </div>
    <div class="buttons" v-show="isInput">
      <UIButton @click="updateSpot" class="actions-button">
        Сохранить
      </UIButton>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapMutations, mapState } from "vuex";
import { updateAct } from "@/modules/instructor-dashboard/services/instrucor-dashboard.services";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIText from "@/modules/UI-kit/UIText.vue";
import { Roles } from "@/enums/enums";

export default {
  name: "PartnerActModal",
  components: { UIButton, UIInput, UIText },
  computed: {
    ...mapState({
      currentAct: (state) => state.instructorDashboardStore.currentAct,
      role: (state) => state.authStore.role,
    }),
    getDateFormat: () => (date) => {
      return `${new Date(date).toLocaleDateString("ru-RU")}`;
    },
  },
  methods: {
    ...mapActions({
      fetchActs: "instructorDashboardStore/fetchActs",
      fetchExecutiveActs: "executiveStore/fetchActs",
    }),
    ...mapMutations({
      closeModal: "modalsStore/closeModal",
    }),
    async toPartner() {
      localStorage.setItem("spotId", String(this.currentAct.partnerActId));
      await this.$router.push({
        path: `/profile/${this.currentAct.partner.id}/finances`,
      });
      this.closeModal();
    },
    toTimeStamp() {
      const regex = /^\d{2}\.\d{2}\.\d{4}$/;
      const dateStr = this.state.date;

      if (!regex.test(dateStr)) {
        throw new Error("Дата введена неверно");
      }

      const [day, month, year] = dateStr.split(".");
      const result = [year, month, day].join("-");
      return dayjs(result).format();
    },
    async updateSpot() {
      try {
        await updateAct(this.role, {
          partner_id: this.currentAct.partner.id,
          end_date: this.toTimeStamp(),
          act_name: this.state.spot,
          partner_act_id: this.currentAct.partnerActId,
        });
        this.closeModal();

        switch (this.role) {
          case Roles.instructor:
            await this.fetchActs();
            break;

          case Roles.executive:
            await this.fetchExecutiveActs();
            break;
        }
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
  },
  data() {
    return {
      state: {
        spot: "",
        partner: "",
        date: "",
      },
      isInput: false,
    };
  },
  mounted() {
    this.state.spot = this.currentAct.name;
    this.state.date = this.getDateFormat(this.currentAct.endDate);
  },
};
</script>

<style lang="scss" scoped>
.partner-info-modal {
  background-color: var(--main-white);
  padding: 1.5rem 1rem;
  border-radius: 12px;
  width: 650px;
  height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.actions {
  padding-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.item-title {
  font-family: var(--proxima-nova-semibold);
  font-size: 13px;
  color: #aaaaaa;
}

.item-value {
  color: var(--main-black);
  font-size: 17px;
  font-family: var(--proxima-nova-regular);
  margin-top: 0.5rem;
}

.buttons {
  display: flex;
  align-items: center;
}

.actions-button {
  width: 50%;
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-family: var(--proxima-nova-semibold);

  &:first-child {
    margin-right: 1rem;
  }
}
</style>
