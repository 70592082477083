import { computed } from "vue";

export function usePriceFormat(price: number, withCurrency = true) {
  let formattedPrice;

  if (withCurrency) {
    formattedPrice = computed(() => {
      return new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
        currencyDisplay: "narrowSymbol",
        minimumFractionDigits: 0,
      }).format(price);
    });
  } else {
    formattedPrice = computed(() => {
      return new Intl.NumberFormat("ru-RU", {
        minimumFractionDigits: 0,
      }).format(price);
    });
  }

  return {
    formattedPrice,
  };
}
