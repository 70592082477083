import SupportPage from "@/modules/support/views/SupportPage.vue";
import { Roles } from "@/enums/enums";

export default [
  {
    name: "Support",
    path: "/support",
    component: SupportPage,
    meta: { role: [Roles.partner] },
  },
];
