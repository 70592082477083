import {
  IAct,
  IFilter,
} from "@/modules/instructor-dashboard/types/instructor-dashboard.types";
import { Nullable } from "@/types/types";
import {
  filterActs,
  getActs,
  instructorDashboardSearch,
} from "@/modules/instructor-dashboard/services/instrucor-dashboard.services";

export default {
  namespaced: true,
  state: {
    acts: [] as IAct[],
    filters: [] as IFilter[],
    currentAct: null as Nullable<IAct>,
    activeFilters: [],
  },
  mutations: {
    setActs(state: any, payload: any) {
      state.acts = payload;
    },
    setInstructorFilters(state: any, payload: any) {
      state.filters = payload;
    },
    setCurrentAct(state: any, payload: IAct) {
      state.currentAct = payload;
    },
    toggleActiveFilter(state: any, payload: any) {
      if (state.activeFilters.includes(payload.name)) {
        state.activeFilters = state.activeFilters.filter(
          (item: any) => item !== payload.name
        );
        const item = state.filters.find(
          (item: any) => item.name === payload.name
        );
        item.selected = false;
      } else {
        state.activeFilters.push(payload.name);
        const item = state.filters.find(
          (item: any) => item.name === payload.name
        );
        item.selected = true;
      }
    },
  },
  actions: {
    async fetchActs(context: any) {
      try {
        const response = await getActs();
        context.commit("setActs", response.data.acts);
        context.commit("setInstructorFilters", response.data.filters);
      } catch (e: any) {
        return e.response;
      }
    },
    async filterActs(context: any, payload: any) {
      try {
        context.commit("toggleActiveFilter", payload);
        const response = await filterActs(context.state.activeFilters);
        context.commit("setActs", response.data.acts);
        context.commit("setInstructorFilters", response.data.filters);
      } catch (e: any) {
        return e.response;
      }
    },
    async searchActs(context: any, { searchString }: { searchString: string }) {
      try {
        const response = await instructorDashboardSearch(searchString);
        context.commit("setActs", response.data.acts);
      } catch (e: any) {
        return e.response;
      }
    },
  },
};
