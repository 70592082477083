<template>
  <div class="secondary-header">
    <p class="secondary-header-title">
      <slot name="title"></slot>
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.secondary-header {
  background-color: var(--main-white);
  padding: 1.5rem 2rem;
  border-radius: 12px;
}

.secondary-header-title {
  font-size: 22px;
  font-family: var(--proxima-nova-semibold);
  line-height: 27px;
}
</style>
