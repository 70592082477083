import App from "./App.vue";
import "vue3-toastify/dist/index.css";
import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";
import Vue3Lottie from "vue3-lottie";
import { createApp } from "vue";
import { initRouter } from "@/router";
import { initStore } from "@/store";

async function initApp() {
  const store = initStore();

  if (localStorage.getItem("accessToken")) {
    await store.dispatch("authStore/checkUser");
  }

  const router = initRouter(store);

  createApp(App)
    .use(store)
    .use(router)
    .use(Vue3Lottie)
    .use(Vue3Toasity, {
      autoClose: 3000,
    } as ToastContainerOptions)
    .mount("#app");
}

initApp().catch((e) => console.log(e));
